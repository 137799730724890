import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import {useEffect, useState} from "react";
import "./TableComponent.css"
import CSVDownloadButton from "./CSVDownloadButton";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";
import LockIcon from "@mui/icons-material/Lock";
import UpgradeDialog from "../../../../components/UpgradeDialog";
import IconButton from "@mui/material/IconButton";
import getNested from "lodash.get";

interface Data {
    [key: string]: any;
}

interface ColumnData {
    dataKey: keyof Data;
    label: string;
    labelCondensed?: string | null;
    showCondensed?: boolean;
    numeric?: boolean;
    type?: 'string' | 'currency' | 'number' | 'percentage';
    width?: number;
}


const columns: ColumnData[] = [
    {label: "Ratio (risk/reward)", labelCondensed: "Risk / Reward", dataKey: "group", type: 'string'},
    {label: "Profit %", labelCondensed: "Profit", showCondensed: true, dataKey: "profitPercentage", type: 'percentage'},
    {label: "Net Profit", showCondensed: false, dataKey: "currentProfit", type: 'currency'},
    {label: 'Gross Profit', showCondensed: false, dataKey: 'grossProfit', type: 'currency'},
    {label: 'Gross Loss', showCondensed: false, dataKey: 'grossLoss', type: 'currency'},
    {label: 'Absolute Drawdown',labelCondensed: "Abs. Drawdown",  dataKey: 'absoluteDrawdownPercentage', type: 'percentage'},
    {label: 'Relative Drawdown', labelCondensed: "Rel. Drawdown", dataKey: 'relativeDrawdownPercentage', type: 'percentage'},
    {label: 'Total Trades', labelCondensed: "Trades", dataKey: 'closedTradeCount', type: 'number'},
    {label: 'Win Rate', labelCondensed: "Win Rate", dataKey: 'winRate', type: 'percentage'},
    {label: 'Consecutive Wins', labelCondensed: "Consec. Wins", dataKey: 'winStreak.streak', type: 'number'},
    {label: 'Consecutive Losses', labelCondensed: "Consec. Losses", dataKey: 'lossStreak.streak', type: 'number'},
    // "grossProfit",
    // "Gross Loss (total of all loss trades)",
    // "Net Profit (%)",
    // "Total Trades (opened and closed = 1 trade)",
    // "AB Maximal Drawdown ($)",
    // "AB Relative Drawdown (%)",
    // "Equity Drawdown (%)",
    // "Profit Factor - Gross Profit divided by absolute value of Gross Loss",
    // "Short Trades (win %)",
    // "Long Trades (win %)",
    // "Profit Trades (% of total)",
    // "Loss Trades (% of total)",
    // "Average Profit Trade",
    // "Average Loss Trade",
    // "Maximum Consecutive wins ($)",
    // "Maximum Consecutive losses ($)",
    // "Average Consecutive win amount",
    // "Average Consecutive loss amount",
]


const VirtuosoTableComponents: any = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props: any) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }: any) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

function FixedHeaderContent({condensed}: any) {

    const width = condensed ? 70 : 120
    const theme = useTheme()

    return (
        <TableRow sx={{
            background: theme.palette.background.custom3
        }}>
            <TableCell style={{width: condensed ? 40 : 50}}>
            </TableCell>
            {columns
                .filter((column) => condensed ? column.showCondensed !== false : true)
                .map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.numeric || false ? 'right' : 'left'}
                    style={{ width: width }}
                >

                    {condensed ? column.labelCondensed : column.label}
                </TableCell>
            ))}
        </TableRow>
    );
}




function RowContent(props: {backtest: any, index: number, row: any, condensed: boolean}) {

    const {backtest, row, condensed} = props

    const theme = useTheme();

    const {
        usermeta,
        canShowcaseViewBacktestSettings,
    } = useApplicationSuiteContext()

    const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);
    const [hoveringLock, setHoveringLock] = React.useState(false);

    function traderOwnsBacktest() {
        return backtest.gcid === usermeta.gcid;
    }

    const locked = !canShowcaseViewBacktestSettings && !traderOwnsBacktest();
    let backgroundColor = theme.palette.background.custom4;

    if (parseFloat(row.currentProfit) > 0) {
        backgroundColor = 'rgb(46, 125, 50)'
    }

    function gatherData(row: any, column: ColumnData) {
        let value = getNested(row, column.dataKey);
        const type = column.type

        if (column.dataKey === 'winRate') {
            if (row['closedTradeCount']) {
                value = row['profitTradeCount'] / row['closedTradeCount'] * 100
            } else {
                value = 0
            }
        }

        return formatData(value, type)
    }


    function formatData(data: any, type?: 'string' | 'currency' | 'number' | 'percentage') {
        if (type === 'currency') {
            return `$${parseFloat(data).toFixed(2)}`
        } else if (type === 'number') {
            return data
        } else if (type === 'percentage') {
            // if 3 digit number or more, round to 0 decimal place
            // if 2 digit number, round to 1 decimal place
            // if 1 digit number, round to 2 decimal places

            const num = parseInt(data)
            const numStr = num.toString()

            if (numStr.length >= 3) {
                return `${parseFloat(data).toFixed(0)}%`
            } else if (numStr.length === 2) {
                return `${parseFloat(data).toFixed(1)}%`
            } else if (numStr.length === 1) {
                return `${parseFloat(data).toFixed(2)}%`
            }

            return `${parseFloat(data).toFixed(0)}%`
        } else {
            return data
        }
    }

    return (
        <React.Fragment>
            <TableCell
                sx={{background: backgroundColor, textAlign: "center"}}
            >
                {
                    locked
                        ? <IconButton onClick={() => setUpgradeDialogOpen(true)}
                                      onMouseEnter={() => setHoveringLock(true)}
                                      onMouseLeave={() => setHoveringLock(false)}
                                      size={'small'}
                        >
                            <LockIcon sx={{color: hoveringLock || upgradeDialogOpen ? '#ffa726' : 'lightgray'}} fontSize={'small'} />
                    </IconButton>
                        : <CSVDownloadButton backtest={backtest} group={row.group}/>
                }

            </TableCell>
            {columns
                .filter((column) => condensed ? column.showCondensed !== false : true)
                .map((column) => (
                <TableCell
                    sx={{background: backgroundColor, py: condensed ? 0 : 1, p:0}}
                    key={column.dataKey}
                    align={column.numeric || false ? 'right' : 'left'}
                >
                    {gatherData(row, column)}
                </TableCell>
            ))}
            {upgradeDialogOpen && <UpgradeDialog open={upgradeDialogOpen} onClose={() => setUpgradeDialogOpen(false)} />}
        </React.Fragment>
    );
}



export default function ReactVirtualizedTable(props: {backtest: any, condensed?: any}) {
    const {backtest, condensed} = props

    const theme = useTheme();

    const [rows, setRows] = useState<any>([])
    const [scrollState, setScrollState] = useState({ canScrollLeft: false, canScrollRight: true });


    const handleScroll = (event: any) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        setScrollState({
            canScrollLeft: scrollLeft > 0,
            canScrollRight: scrollLeft < scrollWidth - clientWidth,
        });
    };

    useEffect(() => {
        if (backtest.summaries) {
            backtest.summaries.sort((a: any, b: any) => {
                return parseFloat(a.currentProfit || Number.MIN_SAFE_INTEGER) - parseFloat(b.currentProfit || Number.MIN_SAFE_INTEGER) > 0 ? -1 : 1;
            })
            setRows(backtest.summaries)
            return
        }

    }, [backtest])

    return (
        <Paper style={{ height:"100%" , width: '100%', position: "relative" }}>
            <Box className={`table-fade ${!scrollState.canScrollLeft ? 'no-left-fade' : ''} ${!scrollState.canScrollRight ? 'no-right-fade' : ''}`}
                sx={{
                    background: theme.palette.background.custom3,
                    "&:before": {
                        left: 0,
                        background: `linear-gradient(to right, ${theme.palette.background.custom3}, rgba(255, 255, 255, 0))`,
                    },
                    "&:after": {
                        right: 0,
                        background: `linear-gradient(to left, ${theme.palette.background.custom3}, rgba(255, 255, 255, 0))`,
                    },
                    "& .MuiTableContainer-root": {
                        background: theme.palette.background.custom3
                    },
                    "& .MuiTable-root": {
                        borderSpacing: "0 2px !important"
                    },
                    "& .MuiTableCell-root": {
                        padding: `${condensed ? 0 : 4}px 1px`,
                    }
                }}
            >
                <TableVirtuoso
                    style={{position: "absolute", top: 0, left: 0}}
                    data={rows}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={() => <FixedHeaderContent condensed={condensed} />}
                    itemContent={(index, row) => <RowContent backtest={backtest} index={index} row={row} condensed={condensed} />}
                    onScroll={handleScroll}
                />
            </Box>
        </Paper>
    );
}
