import React, { useEffect, useState } from 'react';
import { ChartsAxisContentProps, LineChart } from '@mui/x-charts';
import { useApplicationSuiteContext } from "../../contexts/ApplicationSuiteProvider";
import { firebase_functions } from "../../common/firebaseConfig";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { ChartSeriesDefaultized } from "@mui/x-charts/models/seriesType/config";
import Divider from "@mui/material/Divider";

interface TradeSummary {
    range: Date;
    profit: number;
}

interface GroupData {
    group: string;
    data: TradeSummary[];
}

interface ChartDataPoint {
    x: Date;
    y: number;
}

interface ChartDataSeries {
    id?: string;
    data: number[]; // Y-values
    label: string;
    type: 'line';
    showMark?: boolean;
    color?: string;
    lineStyle?: { strokeDasharray: string };
    disableHighlight?: boolean;
    curve?: 'linear' | 'monotoneX' | 'monotoneY' | 'natural' | 'step' | 'stepBefore' | 'stepAfter';
    valueFormatter?: (value: null | number) => string;
}

function BacktestEquityChart(props: {
    backtest: any;
    tradeGroups: string[];
}) {
    const { backtest, tradeGroups } = props;
    const { usermeta } = useApplicationSuiteContext();

    const [chartGroupsData, setChartGroupsData] = useState<Record<string, ChartDataSeries>>({});
    const [xAxisDates, setXAxisDates] = useState<Date[]>([]);
    const [yAxisRange, setYAxisRange] = useState<{ min: number; max: number } | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [getBacktestTradeProfits] = useHttpsCallable(firebase_functions, "getBacktestTradeProfits");

    // Fetch logic encapsulated in a function
    const fetchChartDataForGroups = async (groupsToFetch: string[]) => {
        if (!backtest?.id || groupsToFetch.length === 0) return [];

        setIsLoading(true);
        const groupTradeProfits: GroupData[] = await getBacktestTradeProfits({backtestId: backtest.id, groups: groupsToFetch})
            .then((result: any) => result.data);

        const startDate = new Date(backtest.backtestSettings.dateRange.startDate);
        const normalizedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()-1);

        // If no xAxisDates yet, build them from scratch
        let allDates = xAxisDates;
        if (allDates.length === 0) {
            const allDatesSet = new Set<number>([normalizedStartDate.getTime()]);
            groupTradeProfits.forEach(({ data }) => {
                data.forEach(({ range }: any) => {
                    const rangeDate = new Date(range);
                    const normalizedDate = new Date(rangeDate.getFullYear(), rangeDate.getMonth(), rangeDate.getDate());
                    allDatesSet.add(normalizedDate.getTime());
                });
            });

            allDates = Array.from(allDatesSet)
                .map((timestamp) => new Date(timestamp))
                .sort((a, b) => a.getTime() - b.getTime());

            setXAxisDates(allDates);
        } else {
            // If we already have some dates, ensure these new groups align
            const allDatesSet = new Set<number>(allDates.map(d => d.getTime()));
            groupTradeProfits.forEach(({ data }) => {
                data.forEach(({ range }: any) => {
                    const rangeDate = new Date(range);
                    const normalizedDate = new Date(rangeDate.getFullYear(), rangeDate.getMonth(), rangeDate.getDate());
                    if (!allDatesSet.has(normalizedDate.getTime())) {
                        allDatesSet.add(normalizedDate.getTime());
                    }
                });
            });

            // Re-sort and update
            allDates = Array.from(allDatesSet)
                .map((timestamp) => new Date(timestamp))
                .sort((a, b) => a.getTime() - b.getTime());

            setXAxisDates(allDates);
        }

        // Process each group's data into a ChartDataSeries
        const newSeriesEntries = groupTradeProfits.map(({ group, data }) => {
            const dataPoints = alignChartData(data, allDates);
            const series: ChartDataSeries = {
                id: `${backtest.id}-series-${group}`,
                data: dataPoints.map((point) => point.y),
                valueFormatter: (value: number | null) => {
                    if (value == null) return '';
                    const percentProfit = value / backtest.backtestSettings.analyzeTrades.equity * 100;
                    const decimalsToShow = percentProfit < 1 ? 2 : 0;
                    return `$${value.toFixed(2)} (${(percentProfit).toFixed(decimalsToShow)}%)`
                },
                label: group.replace('-', '/'),
                type: 'line',
                showMark: false
            };

            if (dataPoints[dataPoints.length - 1].y < 0) {
                series.color = `rgba(255, 255, 255, 0.1)`;
                series.disableHighlight = true;
            }

            return [group, series] as const;
        });

        // Update state with newly fetched series
        setChartGroupsData((prev) => {
            const updated = { ...prev };
            for (const [group, series] of newSeriesEntries) {
                updated[group] = series;
            }
            return updated;
        });

        setIsLoading(false);
    };

    // On initial mount or whenever tradeGroups changes, fetch or remove series
    useEffect(() => {
        if (!backtest || !usermeta) return;

        const currentGroups = Object.keys(chartGroupsData);

        // Identify new groups to fetch
        const newGroups = tradeGroups.filter(g => !currentGroups.includes(g));

        // Identify groups to remove
        const removedGroups = currentGroups.filter(g => !tradeGroups.includes(g));

        if (newGroups.length > 0) {
            fetchChartDataForGroups(newGroups);
        }

        if (removedGroups.length > 0) {
            setChartGroupsData((prev) => {
                const updated = { ...prev };
                for (const rg of removedGroups) {
                    delete updated[rg];
                }
                return updated;
            });
        }

        // If we already have some data, we might need to recalculate Y-axis range
        if (tradeGroups.length > 0) {
            recalculateYAxisRange(tradeGroups, chartGroupsData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradeGroups, backtest, usermeta]);

    // Recalculate Y axis range whenever chartGroupsData or xAxisDates changes
    useEffect(() => {
        if (tradeGroups.length > 0) {
            recalculateYAxisRange(tradeGroups, chartGroupsData);
        }
    }, [chartGroupsData, xAxisDates, tradeGroups]);

    const recalculateYAxisRange = (activeGroups: string[], groupData: Record<string, ChartDataSeries>) => {
        const activeSeries = activeGroups.map(g => groupData[g]).filter(Boolean);
        if (activeSeries.length === 0) {
            setYAxisRange(null);
            return;
        }

        const allYValues = activeSeries.flatMap(serie => serie.data);
        const minY = Math.min(...allYValues);
        const maxY = Math.max(...allYValues);
        setYAxisRange({ min: minY+(minY*.1), max: maxY });
    };

    // Build the final chartData array including baseline
    const sortedGroups = tradeGroups
        .map((g) => chartGroupsData[g])
        .filter(Boolean)
        .sort((a, b) => b.data[b.data.length - 1] - a.data[a.data.length - 1]);

    // Baseline series
    const baselineSeries: ChartDataSeries = {
        id: `${backtest?.id}-baseline`,
        data: xAxisDates.map(() => 0),
        label: '',
        type: 'line',
        showMark: false,
        color: 'lightgray',
        lineStyle: { strokeDasharray: '5,5' },
        disableHighlight: true,
    };

    const finalChartData = [...sortedGroups, baselineSeries];

    if (finalChartData.length === 0) {
        return null;
    }

    return (
        <LineChart
            loading={isLoading}
            skipAnimation
            xAxis={[
                {
                    scaleType: 'time',
                    data: xAxisDates,
                    valueFormatter: (value: Date, context) => {
                        if (context.location === 'tooltip') {
                            return `Net profit ${value.toLocaleDateString()}`
                        }
                        return `${value.toLocaleDateString()}`;
                    },
                    min: xAxisDates[0],
                    max: xAxisDates[xAxisDates.length - 1],
                },
            ]}
            yAxis={[
                {
                    valueFormatter: (value: number) => `$${value.toFixed(0)}`,
                    min: yAxisRange?.min,
                    max: yAxisRange?.max,
                },
            ]}
            slots={{
                axisContent: RenderAxisContent,

            }}
            series={finalChartData}
            margin={{ top: 8, right: 10, left: 50, bottom: 24 }}
            slotProps={{
                legend: {hidden: true},
            }}
        />
    );
}

// Align and combine the cumulative profit from daily summaries across all dates
const alignChartData = (
    dailySummaries: TradeSummary[],
    allDates: Date[]
): ChartDataPoint[] => {
    const dataPoints: ChartDataPoint[] = [];
    let cumulativeProfit = 0;

    const profitByDate = new Map<number, number>();
    dailySummaries.forEach((summary) => {
        const summaryDate = new Date(summary.range);
        const normalizedDate = new Date(summaryDate.getFullYear(), summaryDate.getMonth(), summaryDate.getDate());
        profitByDate.set(normalizedDate.getTime(), summary.profit);
    });

    allDates.forEach((date) => {
        const timestamp = date.getTime();
        if (profitByDate.has(timestamp)) {
            cumulativeProfit += profitByDate.get(timestamp) || 0;
        }
        dataPoints.push({
            x: date,
            y: cumulativeProfit,
        });
    });

    return dataPoints;
};

function formatDate(dateValue: Date | string | number | null) {
    if (dateValue == null) return '';
    const date = dateValue instanceof Date ? dateValue : new Date(dateValue);
    return date.toLocaleDateString();
}

const RenderAxisContent = (props: ChartsAxisContentProps) => {
    const { series, dataIndex, axisValue } = props;

    if (dataIndex == null || !series || series.length === 0) {
        return null;
    }

    // Extract hovered values for each series
    const seriesWithValues = series.map(s => {
        const seriesObject = s as unknown as ChartSeriesDefaultized<'line'>;
        const value = typeof dataIndex === 'number' && s.data[dataIndex] != null ? s.data[dataIndex] : null;
        return {
            label: seriesObject.label,
            color: seriesObject.color,
            value: value as any,
            valueFormatter: seriesObject.valueFormatter,
        };
    }).filter(s => s.value !== null && s.value !== 0);

    if (seriesWithValues.length === 0) return null;

    // Sort the series by the hovered value descending
    const topSeries = seriesWithValues.sort((a, b) => (b.value! - a.value!));
    const topTen = topSeries.slice(0, 10);

    return (
        <Box p={1} sx={{ backgroundColor: 'rgba(0, 0, 0, 1)', borderRadius: '4px', color: '#fff' }}>
            <Typography variant="body1" color={'textSecondary'} sx={{mb: 1 }}>
                Net profit {formatDate(axisValue)} {topSeries.length > 10 ? '(Top 10)' : ''}
            </Typography>

            <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', mb: 1 }} />

            {topTen.map((item, index) => {
                const formatedLabel = item.valueFormatter ? item.valueFormatter(item.value, { dataIndex }) : '';

                return (
                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: item.color,
                                    display: 'inline-block',
                                    mr: 1
                                }}
                            />
                            <Typography variant="body1" sx={{ color: '#fff' }}>
                                {item.label ? typeof item.label === 'string' ? item.label : item.label('tooltip') : '' }:
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ color: '#fff', ml: 2, whiteSpace: 'nowrap' }}>
                            {formatedLabel}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default BacktestEquityChart;
