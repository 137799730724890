import Application from "../../../layout/Application";
import {BacktestReportProvider, useBacktestReportContext} from "../contexts/BacktestReportContext";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import BacktestReportSidebar from "./BacktestReportSidebar";
import ReportCardList from "../../../components/backtests/ReportCardList";
import BacktestFilterView from "../../../components/backtests/filter/BacktestFilterView";
import React from "react";

function BacktestReportApp() {
    const theme = useTheme();

    const {
        backtests,
        loadMore,
        isLoading,
        totalCount,
    } = useBacktestReportContext();

    return (
        <Box sx={{ background: theme.palette.background.custom2, overflowY: "hidden" }} height={"100%"} display={"flex"} flexDirection={"column"} gap={1}>
            <BacktestFilterView />
            <ReportCardList backtests={backtests}
                            loadMore={loadMore}
                            isLoading={isLoading}
                            totalCount={totalCount}
                            emptyListText={{
                                title: "No backtests found",
                                subTitle: "Try adjusting your filters or run a new backtest"
                            }}
            />
        </Box>
    );
}

const app = ({ tab }: any) => (
    <Application AppComponent={BacktestReportApp} SidebarComponent={BacktestReportSidebar} ContextProviders={[BacktestReportProvider]} tab={tab} />
);
export default app;
