import React, {useEffect, useRef, useState} from 'react';
import { Box, Card, CardActions, CardContent, Chip, IconButton, Typography,} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {AdminPanelSettings, CalendarMonth, LegendToggle} from '@mui/icons-material';
import {styled, useTheme} from '@mui/material/styles';
import BacktestEquityChart from './BacktestEquityChart';
import _duration from "format-duration-time";
import ReportViewModal from "./ReportViewModal";
import {Tabs} from "../../layout/MainLayout";
import BacktestStatusIcon from "./BacktestStatusIcon";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import BacktestGradeIcon from "./BacktestGradeIcon";
import useRecentlyViewedBacktests from "../../hooks/useRecentlyViewedBacktests";
import Slider, {LazyLoadTypes} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BacktestTable from "../../app/reports/components/backtestResults/BacktestTable";
import CreateAlertActionChip from "../actionChips/CreateAlertActionChip";
import ViewBacktestSettingsActionChip from "../actionChips/ViewBacktestSettingsActionChip";
import ViewPatternActionChip from "../actionChips/ViewPatternActionChip";
import ActionChip from "../actionChips/ActionChip";
import BacktestDescription from "./BacktestDescription";
import FavoriteBacktestActionChip from "../actionChips/FavoriteBacktestActionChip";
import SummaryGradeIcon from "./SummaryGradeIcon";

type BacktestReportCardProps = {
    backtest: any,
    condensed?: boolean,
    selectable?: boolean,
    onSelected?: (backtest: any) => void,
    isSelected?: boolean,
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 600,
    },
});

function BacktestReportCard({ backtest, condensed, selectable, onSelected, isSelected }: BacktestReportCardProps) {
    const theme = useTheme();
    const [reportOpen, setReportOpen] = React.useState(false);
    const [tradeGroupsToGraph, setTradeGroupsToGraph] = useState<string[]>([]);
    const [, setRecentlyViewedBacktests] = useRecentlyViewedBacktests();

    const {
        setActiveTab,
        setPattern,
    } = useApplicationSuiteContext();

    useEffect(() => {
        if (backtest.summaries) {
            const tradeGroups = backtest.summaries
                .sort((a: any, b: any) => (b.currentProfit || 0) > (a.currentProfit || 0) ? 1 : -1)
                .slice(0, 5)
                .map((summary: any) => summary.group);
            setTradeGroupsToGraph(tradeGroups);
        }
    }, [backtest.summaries]);

    const sliderSettings = {
        dots: true,
        lazyLoad: 'ondemand' as LazyLoadTypes,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    function openBacktestReport() {
        if (backtest.status !== "running" && (backtest.summaries && backtest.summaries.length)) {
            setReportOpen(true);
        }
    }

    function goToPatternBuilder(event: any) {
        event.stopPropagation();
        setRecentlyViewedBacktests(backtest);
        setPattern(backtest.pattern);
        setActiveTab(Tabs.PatternBuilder);
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '--Paper-overlay': 'none !important',
                border: selectable ? `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}` : 'none',
            }}
            className={'backtest-report-card'}
        >
            <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems={'flex-start'} p={1}
                 flexGrow={1}
                 onClick={() => openBacktestReport()}
                 sx={{
                     cursor: 'pointer',
                     backgroundColor: theme.palette.background.custom4,
                     '&:hover': {
                         opacity: 0.95,
                     },
                     boxShadow: "inset 0px -3px 8px -3px black",
                 }}
            >

                <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Box overflow={'hidden'}>
                        <Typography variant="body1" color="text.primary" overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} onClick={(event) => goToPatternBuilder(event)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                            textDecoration: 'underline'
                                        }
                                    }}
                        >
                            {backtest.name}
                            <Typography variant={'caption'}> (v{backtest?.pattern?.version || 0})</Typography>
                        </Typography>
                    </Box>
                    {['running', 'error'].includes(backtest.status)
                        ? <BacktestStatusIcon backtest={backtest} />
                        : (backtest.summaries && backtest.summaries.length) ? <BacktestGradeIcon backtest={backtest} /> : null}
                </Box>

                <Box display={'flex'} overflow={'hidden'} width={'100%'}>
                    <BacktestDescription backtest={backtest}/>
                </Box>
            </Box>

            {
                !condensed && <CardContent sx={{
                    height: 200,
                    flexGrow: 1,
                    p: 0,
                    backgroundColor: theme.palette.background.custom3,
                }}>

                <style>
                    {`
                    .backtest-report-card {
                        .slick-list, .slick-track, .slick-slide, .slick-slide > div {
                            height: 100%;
                        }
                        .slick-slider {
                            height: calc(100% - 25px);
                            cursor: grab;
                        }
                        
                        .slick-slider:active {
                          cursor: grabbing !important;
                        }
                      
                        .slick-dots {
                            margin-left: 20px;
                        }
                        
                        .slick-dots li.slick-active button:before {
                            color: ${theme.palette.primary.main};
                        }
                        
                        .slick-dots li button:before {
                            color: ${theme.palette.primary.light};
                        }
                    }
                `}
                </style>

                    {!(backtest.summaries && backtest.summaries.length) && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} flexDirection={'column'}>
                      <Typography variant={'body1'} color={'textPrimary'}>No trades were executed during this backtest</Typography>
                      <Typography variant={'caption'} color={'textSecondary'} textAlign={'center'}>Consider refining your strategy parameters or adjusting the timeframe to generate more meaningful results.</Typography>
                    </Box>}
                    {(backtest.summaries && backtest.summaries.length) && <Slider {...sliderSettings} >
                        {backtest.summaries.some((summary: any) => summary.closedTradeCount) && <BacktestEquityChart backtest={backtest} tradeGroups={tradeGroupsToGraph}/>}
                        {<BacktestTable backtest={backtest} condensed/>}
                    </Slider>}
              </CardContent>
            }
            <CardActions sx={{
                position: 'relative',
                height: condensed ? 30 : 40,
                background: theme.palette.background.custom3,
                p:0,
                px: 1,
                py: .5,
                borderTop: condensed ? 'none' : `1px solid ${theme.palette.divider}`,
            }}>
                {
                    selectable
                        ? <BacktestReportCardSelectFooter backtest={backtest} condensed={condensed} onSelected={onSelected} isSelected={isSelected}/>
                        : <BacktestReportCardBaseFooter backtest={backtest} condensed={condensed} tradeGroupsToGraph={tradeGroupsToGraph} setTradeGroupsToGraph={setTradeGroupsToGraph}/>
                }
            </CardActions>

            {reportOpen && <ReportViewModal
              backtest={backtest}
              open={reportOpen}
              tradeGroupsToGraph={tradeGroupsToGraph}
              setTradeGroupsToGraph={setTradeGroupsToGraph}
              onClose={() => {
                  setRecentlyViewedBacktests(backtest);
                  setReportOpen(false)
              }}
              selectable={selectable}/>
            }
        </Card>
    );
}

function BacktestReportCardSelectFooter({ backtest, condensed, onSelected, isSelected }: any) {
    const {
        usermeta,
    } = useApplicationSuiteContext();

    const [isHovering, setIsHovering] = useState(false);

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}
             onMouseEnter={() => setIsHovering(true)}
             onMouseLeave={() => setIsHovering(false)}
        >
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                {(usermeta.isAdmin && isHovering) && (
                    <CustomWidthTooltip
                        arrow={true}
                        placement={"top-start"}
                        title={
                            <React.Fragment>
                                <Box>
                                    <Box borderBottom={1}>
                                        <Typography variant="body2" color={'textPrimary'}>Admin Only</Typography>
                                    </Box>
                                    <Box>
                                        <Box display={'grid'} gridTemplateRows={'1fr 1fr 1fr'} gridTemplateColumns={'1fr 5fr'} alignItems={'center'}>
                                            <Typography variant="body2" color={'textSecondary'}>Backtest:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Pattern:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.pattern?.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Runner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.gcid}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Owner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.backtestSettings?.gcid}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </React.Fragment>
                        }
                      >
                        <IconButton size={'small'} onClick={() => console.log(backtest)} >
                          <AdminPanelSettings color={'warning'} fontSize={'small'} />
                        </IconButton>
                    </CustomWidthTooltip>
                )
                }
                <ActionChip
                    sx={{ml: 'auto', width: 'auto'}}
                    variant={isSelected ? 'filled' : 'outlined'}
                    label={'Select'}
                    size={condensed ? 'small' : 'medium'}
                    onClick={() => onSelected(backtest)}
                    forceOpen={true}
                />
            </Box>
        </Box>
    )
}

function BacktestReportCardBaseFooter({ condensed, backtest, tradeGroupsToGraph, setTradeGroupsToGraph }: any) {
    const theme = useTheme();

    const {
        setShowReportCardDateTimeTooltips,
        showReportCardDateTimeTooltips,
        usermeta,
    } = useApplicationSuiteContext();

    const isHoveringTradeRatioLegend = useRef(false);

    const [localReportCardDateTimeTooltip, setLocalReportCardDateTimeTooltip] = React.useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isTradeRatioLegendOpen, setIsTradeRatioLegendOpen] = useState(false);

    function renderBacktestDateTime() {
        if (backtest.backtestSettings.startTime === backtest.backtestSettings.endTime) {
            return `All day`;
        }

        return `${new Date(backtest.backtestSettings.startTime).toLocaleTimeString()} to ${new Date(backtest.backtestSettings.endTime).toLocaleTimeString()}`;
    }

    const startDate = new Date(backtest.backtestSettings.dateRange.startDate).toDateString();
    const endDate = new Date(backtest.backtestSettings.dateRange.endDate).toDateString();
    const dateRangeString = `${startDate} to ${endDate}`;
    const timeRangeString = renderBacktestDateTime();

    function toggleAllReportCardDateTimeTooltips(event: any) {
        event.stopPropagation();
        setShowReportCardDateTimeTooltips(!showReportCardDateTimeTooltips);
    }

    // delay the closing of the tooltip to allow the user to hover over the tooltip
    // as they move to hover over the tooltip we should keep the tooltip alive. So before closing the tooltip we should check if the user is hovering over the tooltip
    function closeTradeRatioLegend() {
        isHoveringTradeRatioLegend.current = false;
        setTimeout(() => {
            if (!isHoveringTradeRatioLegend.current) {
                setIsTradeRatioLegendOpen(false);
            }
        }, 300);
    }

    function openTradeRatioLegend() {
        setIsTradeRatioLegendOpen(true);
        isHoveringTradeRatioLegend.current = true;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'} justifyContent={'center'}
             onMouseEnter={() => setIsHovering(true)}
             onMouseLeave={() => setIsHovering(false)}
        >
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} sx={{overflow: 'hidden'}} gap={1}>
                    <FavoriteBacktestActionChip backtest={backtest} size={condensed ? 'small' : 'medium'} />

                    <Tooltip
                        arrow={true}
                        placement={"bottom-start"}
                        open={showReportCardDateTimeTooltips || localReportCardDateTimeTooltip}
                        onClose={() => {
                            setLocalReportCardDateTimeTooltip(false);
                            setShowReportCardDateTimeTooltips(false);
                        }}
                        onOpen={() => setLocalReportCardDateTimeTooltip(true)}
                        title={
                            <React.Fragment>
                                <Box display={'flex'} flexDirection={'column'} gap={1}>
                                    <Box>
                                        <Box marginBottom={1} borderBottom={1}>
                                            <Typography variant="body2" color={'textPrimary'} display={'inline'}>Date Ran </Typography>
                                            <Typography variant="caption" color={'textSecondary'}>(Duration)</Typography>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary">
                                            {new Date(backtest.startTime).toDateString()} ({_duration((backtest.endTime || new Date().getTime()) - backtest.startTime).format('m[m] ss[s]')})
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Box marginBottom={1} borderBottom={1}>
                                            <Typography variant="body2" color={'textPrimary'}>Backtest Date Range</Typography>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary">
                                            {timeRangeString}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {dateRangeString}
                                        </Typography>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        }
                    >
                        <IconButton onClick={(event) => toggleAllReportCardDateTimeTooltips(event)} size={'small'}>
                            <CalendarMonth fontSize={'small'} />
                        </IconButton>
                    </Tooltip>

                    {
                        !!(!condensed && backtest.summaries?.length) && <Tooltip
                        arrow={true}
                        open={isTradeRatioLegendOpen}
                        disableFocusListener={true}
                        placement={"bottom-start"}
                        title={
                            <React.Fragment>
                                <Box
                                    onMouseEnter={() => openTradeRatioLegend()}
                                    onMouseLeave={() => closeTradeRatioLegend()}
                                >
                                    <Box marginBottom={1} borderBottom={1}>
                                        <Typography variant="body2" color={'textPrimary'}>Trade Ratios</Typography>
                                    </Box>
                                    <Box display={"flex"} flexWrap={'wrap'} gap={1}>
                                        {
                                            backtest.summaries
                                                .sort((a: any, b: any) => (b.currentProfit || 0) > (a.currentProfit || 0) ? 1 : -1)
                                                .map((summary: any, index: number) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        label={summary.group}
                                                        size={'small'}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (tradeGroupsToGraph.includes(summary.group)) {
                                                                if (tradeGroupsToGraph.length > 1) {
                                                                    setTradeGroupsToGraph((prev: any) => prev.filter((group: string) => group !== summary.group))
                                                                }
                                                            } else {
                                                                setTradeGroupsToGraph((prev: any) => [...prev, summary.group])
                                                            }
                                                        }}
                                                        sx={{
                                                            backgroundColor: 'transparent',
                                                            borderColor: tradeGroupsToGraph.includes(summary.group) ? theme.palette.primary.main : theme.palette.background.custom1,
                                                        }}
                                                        variant={'outlined'}
                                                        icon={
                                                            <IconButton size={'small'} onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (tradeGroupsToGraph.includes(summary.group)) {
                                                                    if (tradeGroupsToGraph.length > 1) {
                                                                        setTradeGroupsToGraph((prev: any) => prev.filter((group: string) => group !== summary.group))
                                                                    }
                                                                } else {
                                                                    setTradeGroupsToGraph((prev: any) => [...prev, summary.group])
                                                                }
                                                            }}>
                                                                <SummaryGradeIcon summary={summary}/>
                                                            </IconButton>
                                                        }
                                                    />
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>

                            </React.Fragment>
                        }
                      >
                        <IconButton size={'small'}
                                    onMouseEnter={() => openTradeRatioLegend()}
                                    onMouseLeave={() => closeTradeRatioLegend()}
                        >
                            <LegendToggle fontSize={'small'} />
                        </IconButton>
                      </Tooltip>
                    }


                    {
                        (usermeta.isAdmin && isHovering) && <CustomWidthTooltip
                        arrow={true}
                        placement={"bottom-start"}
                        title={
                            <React.Fragment>
                                <Box>
                                    <Box marginBottom={1} borderBottom={1}>
                                        <Typography variant="body2" color={'textPrimary'}>Admin Only</Typography>
                                    </Box>
                                    <Box>
                                        <Box display={'grid'} gridTemplateRows={'1fr 1fr 1fr'} gridTemplateColumns={'1fr 5fr'} alignItems={'center'}>
                                            <Typography variant="body2" color={'textSecondary'}>Backtest:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Pattern:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.pattern?.id}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Runner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.gcid}</Typography>
                                            <Typography variant="body2" color={'textSecondary'}>Owner:</Typography>
                                            <Typography variant={'body1'} mx={'auto'} color={'textPrimary'}>{backtest.backtestSettings?.gcid}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </React.Fragment>
                        }
                      >
                        <IconButton size={'small'} onClick={() => console.log(backtest)} >
                          <AdminPanelSettings color={'warning'} fontSize={'small'} />
                        </IconButton>
                      </CustomWidthTooltip>
                    }

                </Box>

                <Box display={"flex"} gap={1}>
                    <CreateAlertActionChip backtest={backtest} size={condensed ? 'small' : 'medium'}/>
                    <ViewBacktestSettingsActionChip backtest={backtest} size={condensed ? 'small' : 'medium'} />
                    <ViewPatternActionChip backtest={backtest} size={condensed ? 'small' : 'medium'} />
                </Box>
            </Box>
        </Box>
    )
}


export default BacktestReportCard;
