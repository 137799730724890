import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Box, Chip, IconButton, Typography} from "@mui/material";
import React, {useRef, useState} from "react";
import {useTheme} from "@mui/material/styles";
import TimeAgo from "javascript-time-ago";
import _duration from "format-duration-time";
import Tooltip from "@mui/material/Tooltip";
import {CalendarMonth, LegendToggle} from "@mui/icons-material";
import {useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import {Tabs} from "../../layout/MainLayout";
import BacktestTable from "../../app/reports/components/backtestResults/BacktestTable";
import BacktestGradeIcon from "./BacktestGradeIcon";
import ViewBacktestSettingsActionChip from "../actionChips/ViewBacktestSettingsActionChip";
import ViewPatternActionChip from "../actionChips/ViewPatternActionChip";
import BacktestDescription from "./BacktestDescription";
import CreateAlertActionChip from "../actionChips/CreateAlertActionChip";
import BacktestEquityChart from "./BacktestEquityChart";
import FavoriteBacktestActionChip from "../actionChips/FavoriteBacktestActionChip";
import SummaryGradeIcon from "./SummaryGradeIcon";

function ReportViewModal({ backtest, open, onClose, selectable, tradeGroupsToGraph, setTradeGroupsToGraph }: any) {
    const theme = useTheme();

    const _timeAgo = new TimeAgo('en-US')

    const {
        setActiveTab,
        setPattern,
    } = useApplicationSuiteContext();

    const isHoveringTradeRatioLegend = useRef(false);

    const [isTradeRatioLegendOpen, setIsTradeRatioLegendOpen] = useState(false);

    function renderBacktestDateTime() {
        if (backtest.backtestSettings.startTime === backtest.backtestSettings.endTime) {
            return `All day`;
        }

        return `${new Date(backtest.backtestSettings.startTime).toLocaleTimeString()} to ${new Date(backtest.backtestSettings.endTime).toLocaleTimeString()}`;
    }

    const startDate = new Date(backtest.backtestSettings.dateRange.startDate).toDateString();
    const endDate = new Date(backtest.backtestSettings.dateRange.endDate).toDateString();
    const dateRangeString = `${startDate} to ${endDate}`;
    const timeRangeString = renderBacktestDateTime();

    function goToPatternBuilder() {
        setPattern(backtest.pattern);
        setActiveTab(Tabs.PatternBuilder);
        onClose && onClose();
    }

    // delay the closing of the tooltip to allow the user to hover over the tooltip
    // as they move to hover over the tooltip we should keep the tooltip alive. So before closing the tooltip we should check if the user is hovering over the tooltip
    function closeTradeRatioLegend() {
        isHoveringTradeRatioLegend.current = false;
        setTimeout(() => {
            if (!isHoveringTradeRatioLegend.current) {
                setIsTradeRatioLegendOpen(false);
            }
        }, 300);
    }

    function openTradeRatioLegend() {
        setIsTradeRatioLegendOpen(true);
        isHoveringTradeRatioLegend.current = true;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'lg'} onClick={(event) => event.stopPropagation()}>
            <DialogTitle sx={{backgroundColor: theme.palette.background.custom4}}>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>

                    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                        <Box>
                            <Typography variant="h6" color="text.primary" overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} onClick={() => goToPatternBuilder()}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: theme.palette.primary.main,
                                                textDecoration: 'underline'
                                            }
                                        }}
                            >
                                {backtest.name}
                                <Typography variant={'caption'}> (v{backtest?.pattern?.version || 0})</Typography>
                            </Typography>
                        </Box>

                        <BacktestGradeIcon backtest={backtest} />
                    </Box>
                    <Box overflow={'hidden'} width={'100%'}>
                        <BacktestDescription backtest={backtest}/>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box width={'100%'} height={250}>
                    {!!backtest.summaries.some((summary: any) => summary.closedTradeCount) && <BacktestEquityChart backtest={backtest} tradeGroups={tradeGroupsToGraph}/>}
                </Box>
                <Box height={300}>
                    <BacktestTable backtest={backtest} />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{borderTop: `1px solid ${theme.palette.divider}`}}
            >
                <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'} sx={{overflow: 'hidden'}} gap={1}>
                        <FavoriteBacktestActionChip backtest={backtest} size={'medium'} />

                        <Tooltip
                            arrow={true}
                            placement={"top-start"}
                            title={
                                <React.Fragment>
                                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                                        <Box>
                                            <Box marginBottom={1} borderBottom={1}>
                                                <Typography variant="body2" color={'textPrimary'} display={'inline'}>Date Ran </Typography>
                                                <Typography variant="caption" color={'textSecondary'}>(Duration)</Typography>
                                            </Box>
                                            <Typography variant={"body2"} color="textSecondary">{_timeAgo.format(new Date(backtest.startTime))}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {new Date(backtest.startTime).toDateString()} ({_duration((backtest.endTime || new Date().getTime()) - backtest.startTime).format('m[m] ss[s]')})
                                            </Typography>

                                        </Box>
                                        <Box>
                                            <Box marginBottom={1} borderBottom={1}>
                                                <Typography variant="body2" color={'textPrimary'}>Backtest Date Range</Typography>
                                            </Box>
                                            <Typography variant="body2" color="textSecondary">
                                                {timeRangeString}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {dateRangeString}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            }
                        >
                            <IconButton>
                                <CalendarMonth fontSize={'small'} />
                            </IconButton>
                        </Tooltip>

                        {
                            !!(backtest.summaries?.length) && <Tooltip
                            arrow={true}
                            open={isTradeRatioLegendOpen}
                            disableFocusListener={true}
                            placement={"bottom-start"}
                            title={
                                <React.Fragment>
                                    <Box
                                        onMouseEnter={() => openTradeRatioLegend()}
                                        onMouseLeave={() => closeTradeRatioLegend()}
                                    >
                                        <Box marginBottom={1} borderBottom={1}>
                                            <Typography variant="body2" color={'textPrimary'}>Trade Ratios</Typography>
                                        </Box>
                                        <Box display={"flex"} flexWrap={'wrap'} gap={1}>
                                            {
                                                backtest.summaries
                                                    .sort((a: any, b: any) => (b.currentProfit || 0) > (a.currentProfit || 0) ? 1 : -1)
                                                    .map((summary: any, index: number) => {
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                label={summary.group}
                                                                size={'small'}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (tradeGroupsToGraph.includes(summary.group)) {
                                                                        if (tradeGroupsToGraph.length > 1) {
                                                                            setTradeGroupsToGraph((prev: any) => prev.filter((group: string) => group !== summary.group))
                                                                        }
                                                                    } else {
                                                                        setTradeGroupsToGraph((prev: any) => [...prev, summary.group])
                                                                    }
                                                                }}
                                                                sx={{
                                                                    backgroundColor: 'transparent',
                                                                    borderColor: tradeGroupsToGraph.includes(summary.group) ? theme.palette.primary.main : theme.palette.background.custom1,
                                                                }}
                                                                variant={'outlined'}
                                                                icon={
                                                                    <IconButton size={'small'} onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (tradeGroupsToGraph.includes(summary.group)) {
                                                                            if (tradeGroupsToGraph.length > 1) {
                                                                                setTradeGroupsToGraph((prev: any) => prev.filter((group: string) => group !== summary.group))
                                                                            }
                                                                        } else {
                                                                            setTradeGroupsToGraph((prev: any) => [...prev, summary.group])
                                                                        }
                                                                    }}>
                                                                        <SummaryGradeIcon summary={summary}/>
                                                                    </IconButton>
                                                                }
                                                            />
                                                        )
                                                    })
                                            }
                                        </Box>
                                    </Box>

                                </React.Fragment>
                            }
                          >
                            <IconButton size={'small'}
                                        onMouseEnter={() => openTradeRatioLegend()}
                                        onMouseLeave={() => closeTradeRatioLegend()}
                            >
                              <LegendToggle fontSize={'small'} />
                            </IconButton>
                          </Tooltip>
                        }
                    </Box>

                    <Box display={'flex'} gap={2}>
                        <CreateAlertActionChip backtest={backtest} forceOpen={true} disabled={selectable}/>
                        <ViewBacktestSettingsActionChip backtest={backtest} forceOpen={true} disabled={selectable}/>
                        <ViewPatternActionChip backtest={backtest} forceOpen={true} disabled={selectable}/>
                    </Box>

                    <Box>

                        <Button onClick={onClose} color="primary">
                            Close
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ReportViewModal;
