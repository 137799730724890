import {CSVDownload} from "react-csv";
import React, {useEffect, useState} from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {CircularProgress} from "@mui/material";
import {CommonPropTypes} from "react-csv/components/CommonPropTypes";
import {firebase_functions} from "../../../../common/firebaseConfig";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";
import {usePattern} from "../../../../hooks/usePattern";
import IconButton from "@mui/material/IconButton";
import {ISymbol} from "../../../../common/components/dialogs/symbolSelectDialog/SymbolSelect";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {useTheme} from "@mui/material/styles";
import getNested from "lodash.get";

type CSVDownloadButtonProps = {
    group: string,
    backtest: any
}

export default function CSVDownloadButton(props: CSVDownloadButtonProps) {
    const theme = useTheme()
    const {group, backtest} = props

    const [pattern] = usePattern(backtest.backtestSettings.patternId, backtest.backtestSettings.patternVersion, backtest.backtestSettings.gcid || backtest.gcid);

    const [loadingTrades, setLoadingTrade] = useState(false);
    const [csv, setCsv] = useState<any>([]);
    const [tradesDownloaded, setTradesDownloaded] = useState(false);
    const [getBacktestTrades] = useHttpsCallable(firebase_functions, 'getBacktestTrades');

    const {
        symbols,
    } = useApplicationSuiteContext()

    useEffect(() => {
        if (tradesDownloaded) {
            return setTradesDownloaded(false)
        }
    }, [tradesDownloaded])

    function createBacktestSettingsData() {

        const patternStrategy: { [key: string]: any } = {
            'Pattern Strategy': '',
            'Name:': `${backtest.patternName} (v${backtest.backtestSettings.patternVersion})`,
            'Symbol:': backtest.backtestSettings.executionSymbol,
            'Start Date:': new Date(backtest.backtestSettings.dateRange.startDate).toLocaleDateString(),
            'End Date:': new Date(backtest.backtestSettings.dateRange.endDate).toLocaleDateString(),
            'Daily Start Time:': new Date(backtest.backtestSettings.startTime).toLocaleTimeString(),
            'Daily End Time:': new Date(backtest.backtestSettings.endTime).toLocaleTimeString(),
            'Trigger:': `${pattern!.trigger.chartDetails.periodicity} ${pattern!.trigger.chartDetails.timeframe} at post`
        }

        const accountDetails: { [key: string]: any } = {
            'Account Details': '',
            'Starting Equity:': `$${backtest.backtestSettings.analyzeTrades.equity.toFixed(2)}`,
            'Risk Per Trade:': `${(backtest.backtestSettings.analyzeTrades.riskPerTrade * 100)}%`,
            'Commissions (per standard lot):': `$${backtest.backtestSettings.analyzeTrades.commissions.toFixed(2)}`,
            'Max Open Positions:': backtest.backtestSettings.analyzeTrades.tradeLimit ? `${backtest.backtestSettings.analyzeTrades.tradeLimit}` : 'infinite',
            'Hedging Allowed:': backtest.backtestSettings.analyzeTrades.allowHedging ? 'Yes' : 'No',
            'Max Risk:': backtest.backtestSettings.analyzeTrades.maxRisk ? `${(backtest.backtestSettings.analyzeTrades.maxRisk * 100)}%` : 'infinite',
            'Daily Trade Limit:': backtest.backtestSettings.analyzeTrades.dailyTradeLimit ? `${backtest.backtestSettings.analyzeTrades.dailyTradeLimit}` : 'infinite',
        }

        const tradeExit: { [key: string]: any } = {
            'Trade Exit': '',
            'Exit Strategy:': backtest.backtestSettings.generateTrades.exitStrategy === "peak_valley" ? "Fractal" : backtest.backtestSettings.generateTrades.exitStrategy[0].toUpperCase() + backtest.backtestSettings.generateTrades.exitStrategy.slice(1),
            'Risk/Reward:': group,
            'ATR Period:': backtest.backtestSettings.generateTrades.atrPeriod,
            'ATR Chart Period:': backtest.backtestSettings.generateTrades.atrChartPeriod,
            'ATR Chart Timeframe:': backtest.backtestSettings.generateTrades.atrChartTimeframe,
        }

        const fractal: { [key: string]: any } | undefined = backtest.backtestSettings.generateTrades.exitStrategy === "peak_valley" ? {
            'Fractal': '',
            'Fractal Count:': backtest.backtestSettings.generateTrades.fractalCount,
            'Max Candles Lookback:' : backtest.backtestSettings.generateTrades.maxPeakValleyLookback,
            'Price Offset:': backtest.backtestSettings.generateTrades.priceOffset,
        } : undefined

        const incremental: { [key: string]: any } | undefined = backtest.backtestSettings.generateTrades.exitStrategy === "incremental" ? {
            'Incremental': '',
            'Incremental Start:': backtest.backtestSettings.generateTrades.incrementalStart,
            'Incremental Stop:': backtest.backtestSettings.generateTrades.incrementalStop,
            'Incremental Step:': backtest.backtestSettings.generateTrades.incrementalStep,
        } : undefined

        const dynamicTakeProfit: { [key: string]: any } = backtest.backtestSettings.analyzeTrades.dynamicTakeProfit && Object.keys(backtest.backtestSettings.analyzeTrades.dynamicTakeProfit).length && {
            'Dynamic Take Profit': '',
            'Allow TP decrease:': backtest.backtestSettings.analyzeTrades.dynamicTakeProfit.allowDecrease ? 'Yes' : 'No',
            'Allow TP increase:': backtest.backtestSettings.analyzeTrades.dynamicTakeProfit.allowIncrease ? 'Yes' : 'No',
            'Type:': backtest.backtestSettings.analyzeTrades.dynamicTakeProfit.type,
            'ATR Recalculation Period:': backtest.backtestSettings.analyzeTrades.dynamicTakeProfit.atrRecalculationPeriod,
            'ATR Recalculation Timeframe:': backtest.backtestSettings.analyzeTrades.dynamicTakeProfit.atrRecalculationTimeframe,
        }

        const forceExit: { [key: string]: any } = backtest.backtestSettings.analyzeTrades.forceExit && Object.keys(backtest.backtestSettings.analyzeTrades.forceExit).length && {
            'Force exit': '',
            'Force exit period:': backtest.backtestSettings.analyzeTrades.forceExit.time.periodicity,
            'Force exit timeframe:': backtest.backtestSettings.analyzeTrades.forceExit.time.timeframe,
        }

        let header = [
            ['GreenChart Pattern Backtest Report'],
            ['Date Ran', new Date(backtest.startTime).toLocaleString()],
            []
        ]

        for (let i = 0; i < Math.max(Object.keys(patternStrategy).length, Object.keys(accountDetails).length, Object.keys(tradeExit).length); i++) {
            const patternStrategyKey = Object.keys(patternStrategy)[i]
            const accountDetailsKey = Object.keys(accountDetails)[i]
            const tradeExitKey = Object.keys(tradeExit)[i]

            header.push([patternStrategyKey, patternStrategy[patternStrategyKey], accountDetailsKey, accountDetails[accountDetailsKey], tradeExitKey, tradeExit[tradeExitKey]])

            if (fractal) {
                const fractalKey = Object.keys(fractal)[i]
                const path = [fractalKey, fractal[fractalKey]]
                header[header.length - 1].push(...path)
            }

            if (incremental) {
                const incrementalKey = Object.keys(incremental)[i]
                const path = [incrementalKey, incremental[incrementalKey]]
                header[header.length - 1].push(...path)
            }

            if (dynamicTakeProfit) {
                const dynamicTakeProfitKey = Object.keys(dynamicTakeProfit)[i]
                const path = [dynamicTakeProfitKey, dynamicTakeProfit[dynamicTakeProfitKey]]
                header[header.length - 1].push(...path)
            }

            if (forceExit) {
                const forceExitKey = Object.keys(forceExit)[i]
                const path = [forceExitKey, forceExit[forceExitKey]]
                header[header.length - 1].push(...path)
            }
        }

        header.push([])

        return header
    }

    function getSymbolDecimals(symbolObject?: ISymbol): number {

        if (!symbolObject) return 2;

        // return the saved decimal value if it exists
        if (symbolObject.params && symbolObject.params.decimals) {
            return symbolObject.params.decimals;
        }

        // return the decimal value based on the symbol type
        if (symbolObject.type === "forex") {
            if (symbolObject.name.endsWith('JPY')) {
                return 3;
            } else {
                return 5;
            }
        }

        // default to 2 decimal place
        return 2;
    }

    function csvData(trades: any[], symbolObject: ISymbol): CommonPropTypes['data'][] {

        const priceDecimals = getSymbolDecimals(symbolObject);

        let data: any[] = []
        const mainHeader = createBacktestSettingsData()
        data = data.concat(mainHeader)
        data.push([])
        const ratioData = createRatioData()

        data = data.concat(ratioData)
        data.push([])
        data.push([])

        const runningGroupProfit: {[group: string]: number} = {};

        let tradeDocs = trades ?? []

        const format = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format;

        const tradeHeaderIndex = data.length;

        tradeDocs
            .sort((a: any, b: any) => {
                if (a.group > b.group) {
                    return 1
                } else if (a.group < b.group) {
                    return -1
                } else if (a.group === b.group) {
                    return new Date(a.entryTime).getTime() > new Date(b.entryTime).getTime() ? 1 : -1
                }
                return -1
            })
            .map(tradeDoc => {

                if (tradeDoc.status === "closed") {
                    if (runningGroupProfit[tradeDoc.group]) {
                        runningGroupProfit[tradeDoc.group] += tradeDoc.profit
                    } else {
                        runningGroupProfit[tradeDoc.group] = tradeDoc.profit
                    }
                }

                let lotSize = tradeDoc.lotSize || 0

                if (symbolObject.type === "forex") {
                    lotSize /= backtest.backtestSettings.analyzeTrades.contractSize
                }

                const tradeRow: {[key: string]: any} = {
                    "Trade Entry": new Date(tradeDoc.entryTime).toLocaleString(),
                    "Day of Week": daysOfWeeks[new Date(tradeDoc.entryTime).getDay()],
                    "Trade Exit": tradeDoc.status === "closed" && tradeDoc ? new Date(tradeDoc.exitTime).toLocaleString() : "Open Trade",
                    "Time in Trade": tradeDoc.status === "closed" ? getTradeDuration(tradeDoc) : '',
                    "Profit": tradeDoc.status === "closed" ? format(tradeDoc.profit) : '',
                    "Commission": format(tradeDoc.commission || 0),
                    "Running PL": tradeDoc.status === "closed" ? format(runningGroupProfit[tradeDoc.group]) : '',
                    "Open Equity": format(tradeDoc.openEquity || 0),
                    "Take Profit": tradeDoc.tp.toFixed(priceDecimals),
                    "Stop Loss": tradeDoc.sl.toFixed(priceDecimals),
                    "Ratio": tradeDoc.group,
                    "Lot Size": (lotSize).toFixed(2),
                    "Type": tradeDoc.type,
                    "Entry Price": tradeDoc.entryPrice.toFixed(priceDecimals),
                    "Exit Price": tradeDoc.exitPrice ? tradeDoc.exitPrice.toFixed(priceDecimals) : '',
                }

                if (tradeDoc.priceOfFractal) {
                    tradeRow["Fractal Price"] = tradeDoc.priceOfFractal.toFixed(priceDecimals)
                }

                if (tradeDoc.tpTime) {
                    tradeRow["Dynamic Take Profit Calculated At"] = new Date(tradeDoc.tpTime).toLocaleString()
                }

                return tradeRow;
            }).forEach((trade) => {

                if (!data[tradeHeaderIndex]) {
                    data[tradeHeaderIndex] = []
                }

                for (const key of Object.keys(trade)) {
                    if (data[tradeHeaderIndex] && !data[tradeHeaderIndex].includes(key)) {
                        data[tradeHeaderIndex].push(key)
                    }
                }

                data.push(Object.values(trade))
            })


        return data
    }


    const daysOfWeeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


    function getTradeDuration(tradeDoc: any) {
        if (!tradeDoc.exitTime || !tradeDoc.entryTime) {
            return '';
        }

        let diffSecs = Math.floor((tradeDoc.exitTime - tradeDoc.entryTime) / 1000); // milliseconds to seconds
        let diff = '';

        if (diffSecs >= 3600) {
            const hours = Math.floor(diffSecs / 3600);
            diff += `${hours}hr`;
            diffSecs %= 3600; // remaining seconds after whole hours
        }

        if (diffSecs >= 60) {
            const minutes = Math.floor(diffSecs / 60);
            diff += ` ${minutes}m`;
            diffSecs %= 60; // remaining seconds after whole minutes
        }

        // Seconds are included even if they are 0, this is to keep consistency with the original format
        diff += ` ${diffSecs}s`;

        return diff.trim();
    }

    function createRatioData() {
        const columns: {
            label: string,
            dataKey: string,
            type: 'string' | 'currency' | 'number' | 'percentage'
        }[] = [
            {label: "Ratio (risk/reward)", dataKey: "group", type: 'string'},
            {label: "Profit %", dataKey: "profitPercentage", type: 'percentage'},
            {label: "Net Profit", dataKey: "currentProfit", type: 'currency'},
            {label: 'Gross Profit', dataKey: 'grossProfit', type: 'currency'},
            {label: 'Gross Loss', dataKey: 'grossLoss', type: 'currency'},
            {label: 'Absolute Drawdown', dataKey: 'absoluteDrawdownPercentage', type: 'percentage'},
            {label: 'Relative Drawdown', dataKey: 'relativeDrawdownPercentage', type: 'percentage'},
            {label: 'Total Trades', dataKey: 'closedTradeCount', type: 'number'},
            {label: 'Win Rate', dataKey: 'winRate', type: 'percentage'},
            {label: 'Consecutive Wins', dataKey: 'winStreak.streak', type: 'number'},
            {label: 'Consecutive Losses', dataKey: 'lossStreak.streak', type: 'number'},
            {label: 'Buy Trades', dataKey: 'buyTradeCount', type: 'number'},
            {label: 'Sell Trades', dataKey: 'sellTradeCount', type: 'number'},
        ]


        const csv = []

        csv.push(columns.map(column => column.label))

        if (backtest.summaries) {

            for (const row of backtest.summaries) {

                if (row.group === group) {

                    const data = []

                    for (const column of columns) {
                        data.push(gatherData(row, column))
                    }

                    csv.push(data);
                }

            }
        }

        return csv

    }

    function gatherData(row: any, column: any) {
        let value = getNested(row, column.dataKey);
        const type = column.type

        if (column.dataKey === 'winRate') {
            if (row['closedTradeCount']) {
                value = row['profitTradeCount'] / row['closedTradeCount'] * 100
            } else {
                value = 0
            }
        }

        return formatData(value, type)
    }

    function formatData(data: any, type?: 'string' | 'currency' | 'number' | 'percentage') {
        if (type === 'currency') {
            return `$${parseFloat(data).toFixed(2)}`
        } else if (type === 'number') {
            return data
        } else if (type === 'percentage') {
            // if 3 digit number or more, round to 0 decimal place
            // if 2 digit number, round to 1 decimal place
            // if 1 digit number, round to 2 decimal places

            const num = parseInt(data)
            const numStr = num.toString()

            if (numStr.length >= 3) {
                return `${parseFloat(data).toFixed(0)}%`
            } else if (numStr.length === 2) {
                return `${parseFloat(data).toFixed(1)}%`
            } else if (numStr.length === 1) {
                return `${parseFloat(data).toFixed(2)}%`
            }

            return `${parseFloat(data).toFixed(0)}%`
        } else {
            return data
        }
    }

    async function getTradesInGroup() {
        if (!loadingTrades) {
            setLoadingTrade(true)
            try {
                const tradesDocs: any = await getBacktestTrades({backtestId: backtest.id, tradeGroup: group})

                if (!tradesDocs?.data) {
                    return
                }

                const symbol = backtest.backtestSettings.executionSymbol;
                const symbolObject = symbols?.find(symbolDoc => symbolDoc.name === symbol);

                if (symbolObject) {
                    setTradesDownloaded(true)
                    setCsv(csvData(tradesDocs.data.data, symbolObject))
                }

            } catch (err) {
                console.error(err)
            } finally {
                setLoadingTrade(false)
            }
        }

    }

    return (
        <>
            {
                loadingTrades ? <CircularProgress size={29}/> :
                    !tradesDownloaded ?  <IconButton onClick={getTradesInGroup}
                                                     size={'small'}
                                                     sx={{
                                                         color: 'white',
                                                         '&:hover': {
                                                             color: theme.palette.primary.main
                                                        }
                                                     }}>
                            <DownloadIcon
                                fontSize={'medium'}
                            />
                        </IconButton> :
                        <CSVDownload
                            data={csv}
                        />
            }

        </>

    )

}
