import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useBacktestContext } from "../contexts/BacktestContext";
import Box from "@mui/material/Box";
import { usePattern } from "../../../hooks/usePattern";
import { useApplicationSuiteContext } from "../../../contexts/ApplicationSuiteProvider";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { styled, useTheme } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { SnackbarHelperContext } from "../../../contexts/SnackbarHelperContext";
import Typography from "@mui/material/Typography";
import { GreenChartTooltip } from "../../../common/components/GreenChartTooltip";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SSButton from "../../../common/components/actionButton/SSButton";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../../common/firebaseConfig";

export default function BacktestActions() {
    const { usermeta } = useApplicationSuiteContext();
    const { setSnackbarSettings } = useContext(SnackbarHelperContext);
    const theme = useTheme();
    const { backtestSettingInputData, backtestInputIsValid, runBacktest } = useBacktestContext();
    const [getBacktestCost] = useHttpsCallable(firebase_functions, "getBacktestCost")
    const [pattern] = usePattern(backtestSettingInputData.patternId, backtestSettingInputData.patternVersion, backtestSettingInputData.gcid);

    const [isInsufficientFundsDialogVisible, setIsInsufficientFundsDialogVisible] = React.useState(false);
    const [costToRunBacktest, setCostToRunBacktest] = React.useState<number>(0);
    const [insufficientFunds, setInsufficientFunds] = React.useState<boolean>(false);

    useEffect(() => {
        if (usermeta.products && usermeta.products?.backtest) {
            setInsufficientFunds((usermeta.products?.backtest?.accruedCost || 0) + costToRunBacktest >= usermeta.products?.backtest?.maxCost);
        } else {
            setInsufficientFunds(false);
        }
    }, [usermeta, costToRunBacktest]);

    useEffect(() => {
        getBacktestCost({
            startDate: backtestSettingInputData.dateRange.startDate,
            endDate: backtestSettingInputData.dateRange.endDate,
            periodicity: pattern?.trigger.chartDetails.periodicity,
            timeframe: pattern?.trigger.chartDetails.timeframe,
        }).then((response: any) => {
            setCostToRunBacktest(response.data?.price || 0);
        });
    }, [getBacktestCost, backtestSettingInputData, pattern]);

    function saveBacktestSettings() {
        if (backtestInputIsValid) {
            const settings = { ...backtestSettingInputData, id: uuidv4() };
            const savedBacktestOptions = localStorage.getItem('savedBacktestOptions')
            const parsedSavedBacktestOptions = savedBacktestOptions ? JSON.parse(savedBacktestOptions) : [];
            parsedSavedBacktestOptions.push(settings);
            localStorage.setItem('savedBacktestOptions', JSON.stringify(parsedSavedBacktestOptions));
            setSnackbarSettings({ message: 'Backtest settings saved.', severity: 'success', autoHideDuration: 6000 });
        }
    }

    function runBacktestOnValidation() {
        if (backtestInputIsValid) {
            runBacktest(backtestSettingInputData);
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, alignItems: 'center', gap: 2, px: 2 }}>
            <Box
                sx={{
                    fontSize: '16px',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ fontWeight: 'bold', color: insufficientFunds ? theme.palette.danger.main : 'white' }}>
                    {insufficientFunds ? 'Insufficient Funds' : 'Cost to run:'}
                </Box>
                <Box>
                    {costToRunBacktest.toFixed(2)}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', order: { xs: 0, md: 1 }, gap: 2 }}>
                <GreenChartTooltip title={<Typography variant={"body2"}>Save current settings</Typography>} arrow>
                    <Box>
                        <SSButton
                            type={'action'}
                            disabled={!backtestInputIsValid}
                            onClick={saveBacktestSettings}
                        >
                            <CreateNewFolderIcon />
                        </SSButton>
                    </Box>
                </GreenChartTooltip>

                <GreenChartTooltip title={<Typography variant={"body2"}>Run backtest</Typography>} arrow>
                    <Box>
                        <SSButton
                            type={insufficientFunds ? 'danger' : 'success'}
                            disabled={!backtestInputIsValid}
                            onClick={
                                () => insufficientFunds
                                    ? setIsInsufficientFundsDialogVisible(true)
                                    : runBacktestOnValidation()
                            }
                        >
                            <PlayArrowIcon />
                        </SSButton>
                    </Box>
                </GreenChartTooltip>
            </Box>
            <InsufficientFundsDialog open={isInsufficientFundsDialogVisible} onClose={() => setIsInsufficientFundsDialogVisible(false)} />
        </Box>
    )
}

const StyledLinks = styled("a")(({ theme }) => ({
    '&': {
        color: theme.palette.primary.main,
        textDecoration: "none"
    },
    '&:hover': {
        textDecoration: "underline"
    }
}));

function InsufficientFundsDialog({ open, onClose }: any) {
    const upgradePackageURL = "https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite";
    const addonPackageURL = "https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite";

    function openMarketplace() {
        window.open('https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite', '_blank');
        onClose();
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-describedby="insufficient-funds-dialog"
        >
            <DialogTitle>Insufficient Backtest Credits</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Oops! Running this backtest will exceed your available backtesting credit. You can <StyledLinks href={upgradePackageURL} target={"_blank"}>upgrade to a higher package</StyledLinks> or <StyledLinks href={addonPackageURL} target={"_blank"}>purchase more credits</StyledLinks>. You can also try reducing the date range or the frequency of the trigger chart to continue backtesting.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={"outlined"} color={"primary"}>Cancel</Button>
                <Button onClick={openMarketplace} variant={"contained"} color={"primary"}>Go To Marketplace</Button>
            </DialogActions>
        </Dialog>
    </>
}
