import {useState, useCallback, useEffect} from 'react';
import {defaultPattern, Pattern} from "../app/builder/contexts/PatternContext";
import {collection, doc, getDoc, getDocs, query, where, DocumentReference, DocumentSnapshot} from "firebase/firestore";
import {firebase_firestore} from "../common/firebaseConfig";
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";
import * as jsondiffpatch from "jsondiffpatch";

export function usePattern(patternId?: string, patternVersion?: number, gcid?: string): [Pattern | undefined, any] {

    const {
        usermeta,
        tradingHouse,
    } = useApplicationSuiteContext()

    const [pattern, setPattern] = useState<Pattern>();
    const [patternRef, setPatternRef] = useState<string>();

    const fetchVersionedPattern = useCallback(async (patternSnapshot: DocumentSnapshot<unknown>) => {
        if (!patternId) {
            return;
        }

        if (!patternVersion) {
            setPattern(patternSnapshot.data() as Pattern);
            setPatternRef(patternSnapshot.ref.path);
            return;
        } else {
            try {
                const versionsRef = collection(firebase_firestore, `${patternSnapshot.ref.path}/versions`);
                const patternVersionQuery = query(versionsRef, where("version", "<=", patternVersion));
                const querySnapshot = await getDocs(patternVersionQuery);
                const patternVersionHistory = querySnapshot.docs
                    .map(doc => doc.data())
                    .sort((a, b) => a.version - b.version); // Ensure they are sorted by version

                if (patternVersionHistory.length === 0) {
                    throw new Error("No version histories found for the specified pattern version.");
                }

                const reconstructedPattern = reconstructToVersion(patternVersion, patternVersionHistory);
                /*
                This is because reconstructing patterns from versions does not include the tradingHouse
                Trading houses were initially manually added to each pattern and are not recorded in versioning
                */
                reconstructedPattern.tradingHouse = tradingHouse;
                setPattern(reconstructedPattern);
                setPatternRef(patternSnapshot.ref.path);
            } catch (e) {
                console.error("Failed to fetch pattern version:", e);
                setPattern(patternSnapshot.data() as Pattern);
                setPatternRef(patternSnapshot.ref.path);
            }
        }
    }, [patternId, patternVersion, tradingHouse]);

    const getDocFromRef = useCallback(async (patternRefValue: DocumentReference<unknown>) => {
        const docSnapshot = await getDoc(patternRefValue);
        if (docSnapshot.exists()) {
            fetchVersionedPattern(docSnapshot);
        } else {
            throw new Error("Pattern not found")
        }
    }, [fetchVersionedPattern]);

    useEffect(() => {
        if (!patternId) {
            setPattern(defaultPattern);
            setPatternRef(undefined);
            return;
        }

        const usermetaPatternRef = doc(firebase_firestore, `usermeta/${usermeta.gcid}/patterns/${patternId}`);
        const standardPatternRef = doc(firebase_firestore, `tradingHouses/${tradingHouse}/standardPatterns/${patternId}`);
        const otherUserPatternRef = doc(firebase_firestore, `usermeta/${gcid}/patterns/${patternId}`);

        getDocFromRef(usermetaPatternRef)
            .catch(() => getDocFromRef(standardPatternRef))
            .catch(() => getDocFromRef(otherUserPatternRef))
            .catch((err) => console.error("Failed to fetch pattern", err));

    }, [patternId, patternVersion, usermeta, getDocFromRef, gcid, tradingHouse]);


    /**
     * Reconstructs an object to the given version using a history of diffs.
     * @param {number} targetVersion - The version to reconstruct.
     * @param {Array} histories - The array of history objects, each with a version and diff.
     * @returns {Object} - The reconstructed object at the specified version.
     */
    function reconstructToVersion(targetVersion: number, histories: any[]) {
        // Check if target version is valid
        const latestVersion = histories[histories.length - 1].version;
        if (targetVersion > latestVersion) {
            console.warn("The target version is greater than the latest version in the histories provided.");
            return null;
        }

        let obj = jsondiffpatch.patch({}, JSON.parse(histories[0].diff));  // Start with version 1

        // Apply each diff sequentially until we reach the desired version
        for (let i = 1; i < targetVersion; i++) {
            obj = jsondiffpatch.patch(obj, JSON.parse(histories[i].diff));
        }

        return obj;
    }

    return [pattern, patternRef]
}
