import {Paper, Popper, Typography} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import BacktestGradeDrawer from "./BacktestGradeDrawer";

function BacktestGradeIcon({ backtest }: any) {

    const theme = useTheme();

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [arrowRef, setArrowRef] = React.useState(null);

    if (!backtest) {
        return null
    }

    if (!backtest.summary || !backtest.summary.grades) {
        return null
    }

    function getLetterGrade(grade: number): string {
        if (grade >= 90) return "A";
        if (grade >= 80) return "B";
        if (grade >= 70) return "C";
        if (grade >= 60) return "D";
        return "F";
    }

    function getGradeColor(grade: number): string {
        if (grade >= 90) return "green";
        if (grade >= 80) return "lightgreen";
        if (grade >= 70) return "yellow";
        if (grade >= 60) return "orange";
        return "red";
    }

    return (
        <>
            <Typography variant="h6"
                        sx={{
                            textShadow: '0 0 20px currentColor',
                        }}
                        color={getGradeColor(backtest.summary.grades.grade)}
                        ref={anchorRef}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}>
                {getLetterGrade(backtest.summary.grades.grade)}
            </Typography>
            <Popper
                modifiers={
                [
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
                open={open}
                anchorEl={anchorRef.current}
                placement={'bottom-end'}
                disablePortal={false}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                sx={{zIndex: 9999}}
                onClick={(event) => event.stopPropagation()}
            >
                <Box sx={{
                    position: 'absolute',
                    fontSize: 7,
                    left: 0,
                    marginLeft: '-0.9em',
                    marginTop: '-1.9em',
                    height: '3em',
                    width: '1em',
                    '&::before': {
                        content: '""',
                        margin: 'auto',
                        display: 'block',
                        width: 0,
                        height: 0,
                        borderStyle: 'solid',
                        borderWidth: '1em 1em 1em 1em',
                        borderColor: `transparent transparent ${theme.palette.background.custom3} transparent`,
                    },
                }} ref={setArrowRef} className="arrow"/>
                <Paper sx={{
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.custom3,
                    '--Paper-overlay': 'none !important',
                }}>
                    <Box display={'grid'} gridTemplateRows={'1fr'} gridTemplateColumns={'6fr 1fr'} alignItems={'center'} marginBottom={1} borderBottom={1}>
                        <Typography variant="body2" color={'textPrimary'}>Backtest Grades</Typography>
                        <InfoIcon fontSize={'small'}
                                  sx={{
                                      m: 'auto',
                                      '&:hover': {
                                          cursor: 'pointer',
                                          color: '#00A1FF',
                                      },
                                  }}
                                    onClick={() => setOpenDrawer(true)}
                        />
                    </Box>
                    <Box display={'grid'} gridTemplateRows={'1fr 1fr 1fr'} gridTemplateColumns={'6fr 1fr'} alignItems={'center'}>
                        <Typography variant="body2" color={'textSecondary'}>Profitability</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(backtest.summary.grades.profitGrade)}>{getLetterGrade(backtest.summary.grades.profitGrade)}</Typography>
                        <Typography variant="body2" color={'textSecondary'}>Abs. Drawdown</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(backtest.summary.grades.absoluteDrawdownGrade)}>{getLetterGrade(backtest.summary.grades.absoluteDrawdownGrade)}</Typography>
                        <Typography variant="body2" color={'textSecondary'}>Rel. Drawdown</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(backtest.summary.grades.relativeDrawdownGrade)}>{getLetterGrade(backtest.summary.grades.relativeDrawdownGrade)}</Typography>
                        <Typography variant="body2" color={'textSecondary'}>Date Range</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(backtest.summary.grades.dateRangeGrade)}>{getLetterGrade(backtest.summary.grades.dateRangeGrade)}</Typography>
                        <Typography variant="body2" color={'textSecondary'}>Number of Ratios</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(backtest.summary.grades.tradeRatioGrade)}>{getLetterGrade(backtest.summary.grades.tradeRatioGrade)}</Typography>
                    </Box>
                </Paper>

            </Popper>
            {openDrawer && <BacktestGradeDrawer isOpen={openDrawer} onClose={() => setOpenDrawer(false)}/>}
        </>
    );
}

export default BacktestGradeIcon;
