import {Alert, useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../../common/firebaseConfig";
import React, {useCallback, useMemo} from "react";
import usePaginatedLoader from "../../../hooks/usePaginatedLoader";
import ReportCardList from "../../backtests/ReportCardList";
import flattenPattern from "../../../common/utils/flatternPattern";
import {Pattern} from "../../../app/builder/contexts/PatternContext";
import _cloneDeep from "lodash.clonedeep";
import Box from "@mui/material/Box";

function AlertPatternSelectForm({alert, onAlertKeyChange}: { alert: Alert, onAlertKeyChange: (key: string, value: any) => void }) {

    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const [getShowcaseBacktests] = useHttpsCallable(firebase_functions, "getBacktests");
    const [backtestIdSelected, setBacktestIdSelected] = React.useState<string | null>(null);

    const backtestFilters = useMemo(() => {
        const filters: any = {};
        if (tradingHouse === 'greenchart') {
            if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                filters.backtestIds = ['WYZnhReGmi5TJQX7xl0w', 'FcTWrgpfwIlcqxuNfb8P', '1GB4k3n9ID45nX5YWEGu', 'fZLnQrPWFe2ZhxRqL5cw']
            }
        } else if (tradingHouse === 'gtf') {
            if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                filters.backtestIds = ['H8lYtQNUxNbYL203PQxm', 'PMbBKlOYVwVSXccBmzS1', 'dWh5meSfUm08wiQMqnP8', 'XNiopi7QQmjDb0Fk6qmk'];
            }
        }
        return filters
    }, [tradingHouse]);

    const func = useCallback(
        (params: any) => getShowcaseBacktests({...params, tradingHouse, type: 'showcase', backtestFilters: backtestFilters, sort: {
                field: "maxProfitPercentage",
                direction: "desc",
            }}),
        [getShowcaseBacktests, tradingHouse, backtestFilters]
    );

    const { data: backtests, isLoading } = usePaginatedLoader({
        queryFunction: func,
        take: 4,
    });

    function handleBacktestSelect(backtest: any) {
        setBacktestIdSelected(backtest.id);

        const pattern: Pattern = _cloneDeep(backtest.pattern);

        const conditions = flattenPattern(pattern);

        for (const condition of conditions) {
            condition.chartDetails.symbol = backtest.backtestSettings.executionSymbol;
        }

        pattern.trigger.chartDetails.symbol = backtest.backtestSettings.executionSymbol;

        onAlertKeyChange("pattern", pattern)
        onAlertKeyChange('name', `${backtest.backtestSettings.executionSymbol} - ${pattern.name}`)

        function getTimeComponents(epochTime: number) {
            const date = new Date(epochTime);
            const hour = date.getUTCHours().toString().padStart(2, '0'); // Use getUTCHours for UTC
            const minute = date.getUTCMinutes().toString().padStart(2, '0'); // Use getUTCMinutes for UTC
            return { hour, minute };
        }

        const { hour: startTimeHour, minute: startTimeMinute } = getTimeComponents(backtest.backtestSettings.startTime);
        const { hour: endTimeHour, minute: endTimeMinute } = getTimeComponents(backtest.backtestSettings.endTime);

        onAlertKeyChange('timeRanges', [{
            startTimeRange: `${startTimeHour}:${startTimeMinute}`,
            endTimeRange: `${endTimeHour}:${endTimeMinute}`
        }]);
    }

    return (
        <>
            <Box height={470} sx={{overflowY: 'auto'}}>
                <ReportCardList
                    backtests={backtests}
                    isLoading={isLoading}
                    condensed={true}
                    selectable={true}
                    onSelected={(selectedBacktest) => handleBacktestSelect(selectedBacktest)}
                    selectedBacktestId={backtestIdSelected}
                    emptyListText={{
                        title: "No backtests found",
                        subTitle: "Try adjusting your filters"
                    }}
                />
            </Box>

        </>
    )
}

export default AlertPatternSelectForm
