import {
    Box,
} from "@mui/material";
import { useTheme} from "@mui/material/styles";
import React from "react";
import Application from "../../../layout/Application";
import {ShowcaseProvider} from "../contexts/ShowcaseContext";
import ShowcaseSidebar from "./ShowcaseSidebar";
import ShowcaseFilterView from "../../../components/backtests/filter/ShowcaseFilterView";
import ShowcaseBacktestReports from "../components/ShowcaseBacktestReports";

function ShowcaseApp() {
    const theme = useTheme();

    return (
        <>
            <Box  sx={{ background: theme.palette.background.custom2, overflowY: "hidden" }} height={"100%"} display={"flex"} flexDirection={"column"} gap={1}>
                <ShowcaseFilterView />
                <ShowcaseBacktestReports />
            </Box>
        </>
    );
}

const App = ({tab}: any) => <Application AppComponent={ShowcaseApp} SidebarComponent={ShowcaseSidebar} ContextProviders={[ShowcaseProvider]} tab={tab}/>
export default App;
