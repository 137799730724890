import React, {useState} from "react";
import BacktestReportCard from "./BacktestReportCard";
import Box from "@mui/material/Box";
import {Skeleton, Typography} from "@mui/material";
import {
    GridChildComponentProps,
    GridOnItemsRenderedProps,
    FixedSizeGrid
} from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from 'react-virtualized-auto-sizer';
import {Variant} from "@mui/material/styles/createTypography";

type ReportCardListProps = {
    backtests?: any[];
    condensed?: boolean;
    loadMore?: () => void;
    isLoading?: boolean;
    totalCount?: number;
    emptyListText?: {
        title: string;
        subTitle?: string;
        size?: 'small' | 'medium' | 'large';
    };
    selectable?: boolean;
    onSelected?: (selected: any) => void;
    selectedBacktestId?: any;
}

function ReportCardList({
                            backtests = [],
                            condensed,
                            loadMore,
                            isLoading,
                            totalCount = backtests?.length || 0,
                            emptyListText,
                            selectable,
                            onSelected,
                            selectedBacktestId,
                        }: ReportCardListProps) {

    const [scrollOffset, setScrollOffset] = useState(0);

    const cardWidth = condensed ? (selectable ? 300 : 250) : 350;
    const cardHeight = condensed ? 115 : 327;
    const GUTTER_SIZE = 8;

    const itemCount = totalCount ?? backtests.length;

    const isItemLoaded = (index: number) => {
        return index < backtests.length;
    };

    function renderNoBacktests() {
        let titleVariant: Variant = 'h5'
        let subTitleVariant: Variant = 'body2';

        if (emptyListText?.size === 'small') {
            titleVariant = 'h6';
            subTitleVariant = 'body2';
        } else if (emptyListText?.size === 'large') {
            titleVariant = 'h4';
            subTitleVariant = 'h6';
        }

        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography
                        variant={titleVariant}
                        color={"textSecondary"}
                        fontWeight={'normal'}
                    >
                        {emptyListText?.title}
                    </Typography>
                    {emptyListText?.subTitle && <Typography variant={subTitleVariant} color={"textSecondary"}>{emptyListText.subTitle}</Typography>}
                </Box>
            </Box>
        );
    }

    // Convert (row, column) to linear index
    const indexFromCoords = (rowIndex: number, columnIndex: number, columnCount: number) => {
        return rowIndex * columnCount + columnIndex;
    };

    if ((!backtests || backtests.length === 0) && !isLoading) {
        return (
            <Box
                p={1}
                sx={{
                    backgroundColor: 'transparent',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                }}>
                {renderNoBacktests()}
            </Box>
        );
    }

    if ((!backtests || backtests.length === 0) && isLoading) {
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography
                        variant={'h5'}
                        color={"textSecondary"}
                        fontWeight={'normal'}
                    >
                        Loading...
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
                height: '100%',
                width: '100%',
            }}
        >
            <Box flex={'1 1 auto'}>
                <AutoSizer>
                    {({ height, width }: any) => {
                        // Calculate columns and rows based on the available width/height
                        const columnCount = Math.max(1, Math.floor((width-3-GUTTER_SIZE) / cardWidth));
                        const rowCount = Math.ceil(itemCount / columnCount);
                        const dynamicCardWidth = Math.floor((width-3-GUTTER_SIZE) / columnCount);

                        return (
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={(startIndex, stopIndex) => {
                                    // Load more items if necessary
                                    return loadMore ? Promise.resolve(loadMore()) : Promise.resolve();
                                }}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <FixedSizeGrid
                                        initialScrollTop={scrollOffset}
                                        onScroll={(scrollProps) => {
                                            // Save current scroll position so we can restore later
                                            setScrollOffset(scrollProps.scrollTop);
                                        }}
                                        ref={ref}
                                        height={height}
                                        width={width}
                                        columnCount={columnCount}
                                        columnWidth={dynamicCardWidth}
                                        rowCount={rowCount}
                                        rowHeight={cardHeight}
                                        itemData={{containerWidth: width, cardHeight, cardWidth, gutterSize: GUTTER_SIZE, backtests,
                                            selectable, onSelected, selectedBacktestId, condensed}}
                                        itemKey={({ columnIndex, rowIndex, data }) => {
                                            let itemKey = `${rowIndex}-${columnIndex}`;
                                            if (backtests && isItemLoaded(indexFromCoords(rowIndex, columnIndex, columnCount))) {
                                                itemKey = `${backtests[indexFromCoords(rowIndex, columnIndex, columnCount)].id}-${rowIndex}-${columnIndex}`;
                                            }

                                            return itemKey;
                                        }}
                                        onItemsRendered={({
                                            overscanColumnStopIndex,
                                            overscanRowStopIndex,
                                            overscanRowStartIndex,
                                            overscanColumnStartIndex,
                                              visibleRowStartIndex,
                                              visibleRowStopIndex,
                                              visibleColumnStartIndex,
                                              visibleColumnStopIndex,
                                          }: GridOnItemsRenderedProps) => {
                                            // Convert grid coords to linear indices
                                            const startIndex = indexFromCoords(visibleRowStartIndex, visibleColumnStartIndex, columnCount);
                                            const stopIndex = indexFromCoords(visibleRowStopIndex, visibleColumnStopIndex, columnCount);

                                            const overscanStartIndex = indexFromCoords(overscanRowStartIndex, overscanColumnStartIndex, columnCount);
                                            const overscanStopIndex = indexFromCoords(overscanRowStopIndex, overscanColumnStopIndex, columnCount);

                                            onItemsRendered({
                                                overscanStartIndex: overscanStartIndex,
                                                overscanStopIndex: overscanStopIndex,
                                                visibleStartIndex: startIndex,
                                                visibleStopIndex: stopIndex,
                                            });
                                        }}
                                    >
                                        {Cell}
                                    </FixedSizeGrid>
                                )}
                            </InfiniteLoader>
                        );
                    }}
                </AutoSizer>
            </Box>
        </Box>
    );
}

const Cell = ({ rowIndex, columnIndex, style, data }: GridChildComponentProps) => {
    const {
        backtests,
        cardWidth,
        gutterSize,
        selectable,
        onSelected,
        selectedBacktestId,
        condensed,
    } = data;

    const isItemLoaded = (index: number) => {
        return index < backtests.length;
    };

    // Convert (row, column) to linear index
    const indexFromCoords = (rowIndex: number, columnIndex: number, columnCount: number) => {
        return rowIndex * columnCount + columnIndex;
    };

    const columnCount = Math.max(1, Math.floor((+(data.containerWidth || 0)-10-gutterSize) / cardWidth));
    const index = indexFromCoords(rowIndex, columnIndex, columnCount);

    if (!backtests || !isItemLoaded(index)) {
        return null
    }

    if (isItemLoaded(index)) {
        const backtest = backtests[index];

        if (!backtest) {
            return null;
        }

        return (
            <Box key={backtest.id}
                 style={{
                     ...style,
                     width: +(style.width || 0) - gutterSize,
                     height: +(style.height || 0) - gutterSize,
                     left: +(style.left || 0) + gutterSize,
                     top: +(style.top || 0) + gutterSize,
                 }}
            >
                {/*{rowIndex}*/}
                {/*-{columnIndex}*/}
                {/*-{index}*/}
                {/*-{columnCount}*/}
                {/*-{cardWidth}*/}
                <BacktestReportCard
                    condensed={condensed}
                    backtest={backtest}
                    selectable={selectable}
                    onSelected={onSelected}
                    isSelected={selectedBacktestId === backtest.id}
                />
            </Box>
        );
    } else {
        return (
            <Box key={`${rowIndex}-${columnIndex}`}
                 style={{
                     ...style,
                     width: +(style.width || 0) - gutterSize,
                     height: +(style.height || 0) - gutterSize,
                     left: +(style.left || 0) + gutterSize,
                     top: +(style.top || 0) + gutterSize,
                 }}>
                <Skeleton variant="rounded" animation="wave" height={'100%'}/>
            </Box>
        );
    }
}

export default ReportCardList;
