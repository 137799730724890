import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import SymbolSelectFilter from "./filters/SymbolSelectFilter";
import SortSelect from "./sort/SortSelect";
import {useShowcaseContext} from "../../../app/showcase/contexts/ShowcaseContext";
import FavoriteToggle from "./filters/FavoriteToggle";
import NetProfitFilterButton from "./buttons/NetProfitFilterButton";
import DrawdownFilterButton from "./buttons/DrawdownFilterButton";
import DateRangeFilterButton from "./buttons/DateRangeFilterButton";
import MoreFilterButton from "./buttons/MoreFilterButton";
import PatternNameSearchFilter from "./filters/PatternNameSearchFilter";
import TriggerChartButton from "./buttons/TriggerChartButton";

function ShowcaseFilterView({condensed}: any) {

    const {
        backtestFilters,
        setBacktestFilters,
        backtestSort,
        setBacktestSort,
        type,
        setType,
    } = useShowcaseContext();

    const containerRef = useRef(null);
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const currentRef = containerRef.current; // Capture the ref

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === currentRef) {
                    const width = entry.contentRect.width;
                    setIsNarrow(width < 900);
                }
            }
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <Box display={"flex"} width={'100%'} height={40} alignItems={'center'} gap={1} pt={.5} ref={containerRef}>
            <FavoriteToggle toggleValue={type === 'showcase_favorites'} onToggle={(val: boolean) => setType(val ? 'showcase_favorites' : 'showcase')}/>
            <Box display={"flex"} width={'100%'} height={40} gap={2} alignItems={'center'}>
                <PatternNameSearchFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                <SymbolSelectFilter backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>

                {(isNarrow || condensed)
                    ? <MoreFilterButton backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                    : <>
                        <TriggerChartButton backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                        <NetProfitFilterButton backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                        <DrawdownFilterButton backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                        <DateRangeFilterButton backtestFilters={backtestFilters} setBacktestFilters={setBacktestFilters}/>
                    </>

                }

                <SortSelect backtestSort={backtestSort} setBacktestSort={setBacktestSort}/>
            </Box>

        </Box>
    );
}

export default ShowcaseFilterView;
