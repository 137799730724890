import {
    Box,
    FormControl, Grid,
    InputAdornment,
    InputLabel, MenuItem,
    OutlinedInput, Select, Switch,
} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {firebase_functions} from "../../../common/firebaseConfig";
import Typography from "@mui/material/Typography";
import {styled, useTheme} from "@mui/material/styles";
import InfoIcon from '@mui/icons-material/Info';
import ExitStrategyDrawer from "./ExitStrategyDrawer";
import ExitStrategyInputs from "./exitStrategies/ExitStrategyInputs";
import {GreenChartTooltip} from "../../../common/components/GreenChartTooltip";
import {useBacktestContext} from "../contexts/BacktestContext";
import CollapsibleInputContainer from "./CollapsibleInputContainer";
import dayjs from "dayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {TimePicker} from "@mui/x-date-pickers";
import getNested from "lodash.get";
import setNestedValue from "lodash.set";
import {SnackbarHelperContext} from "../../../contexts/SnackbarHelperContext";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import {usePattern} from "../../../hooks/usePattern";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {ISymbol} from "../../../common/components/dialogs/symbolSelectDialog/SymbolSelect";
import getContractSize from "../../../common/utils/getContractSize";
import BacktestActionBar from "./actionBars/BacktestActionBar";
import BacktestHeaderBar from "./actionBars/BacktestHeaderBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import PatternSelect from "../../../components/form/PatternSelect";
import SymbolSelect from "../../../components/form/SymbolSelect";

const RequiredInputSettings = {
    patternId: "A pattern must be selected",
    generateTrades: {
        tradeRatios: "A risk/reward must be selected",
    }
}

const StyledContainer = styled(Box)(({ theme }) => ({
    '& .MuiInputBase-root, & .MuiSelect-root': {
        backgroundColor: theme.palette.background.custom4
    }
}));

const defaultBacktestMinDate = new Date(2000, 0, 1);

export default function BacktestInput() {
    const {
        setBacktestInputIsValid,
        backtestSettingInputData,
        setBacktestSettingInputData,
    } = useBacktestContext();

    const {
        usermeta,
        symbols,
    } = useApplicationSuiteContext()

    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const [pattern] = usePattern(backtestSettingInputData.patternId, backtestSettingInputData.patternVersion, backtestSettingInputData.gcid)

    const [isExitStrategyDrawerOpen, setIsExitStrategyDrawerOpen] = useState(false);
    const [formErrors, setFormErrors] = useState<Partial<typeof RequiredInputSettings>>({});
    const [pickerKey, setPickerKey] = useState<number>(Date.now());

    const [isAllDaySelected, setIsAllDaySelected] = useState(true);
    
    const [startTempTime, setStartTempTime] = useState<any>(backtestSettingInputData.startTime);
    const startTempTimeRef = useRef(startTempTime);
    const [endTempTime, setEndTempTime] = useState<any>(backtestSettingInputData.endTime);
    const endTempTimeRef = useRef(endTempTime);

    const [minDateForSymbol, setMinDateForSymbol] = useState<Date>(defaultBacktestMinDate);
    const [lastStartTimeInput, setLastStartTimeInput] = useState<any>(backtestSettingInputData.startTime);
    const [lastEndTimeInput, setLastEndTimeInput] = useState<any>(backtestSettingInputData.endTime);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [getMinDateForSymbol] = useHttpsCallable(
        firebase_functions,
        'getMinDateForSymbol'
    );

    const handleInputChange = useCallback((name: string, value: any) => {
        if (getNested(formErrors, name)) {
            setFormErrors((prevState) => {
                const newState = { ...prevState };
                setNestedValue(newState, name, undefined)
                return newState;
            });
        }

        setBacktestSettingInputData((prevState: any) => {
            const newState = { ...prevState };
            setNestedValue(newState, name, value);
            return newState;
        })
    }, [formErrors, setBacktestSettingInputData]);

    useEffect(() => {

        if (backtestSettingInputData.executionSymbol) {
            getMinDateForSymbol(backtestSettingInputData.executionSymbol).then((minDate: any) => {
                if (minDate && minDate.data) {
                    setMinDateForSymbol(new Date(minDate.data));
                } else {
                    setMinDateForSymbol(defaultBacktestMinDate);
                }
            });
        }

    }, [backtestSettingInputData.executionSymbol, getMinDateForSymbol])

    useEffect(() => {
        if (backtestSettingInputData) {
            setBacktestInputIsValid(!getInvalidInputs().length)

            if (backtestSettingInputData.startTime !== backtestSettingInputData.endTime) {
                setIsAllDaySelected(false)
            }
        }

        validateInput();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backtestSettingInputData])

    useEffect(() => {
        if (pattern && pattern.version) {
            setBacktestSettingInputData((prevState: any) => {
                const newState = { ...prevState };
                setNestedValue(newState, "patternVersion", pattern.version);
                return newState;
            })
        }
    }, [pattern, setBacktestSettingInputData]);


    function getInvalidInputs() {
        return [
            {key: 'generateTrades.tradeRatios', message: RequiredInputSettings.generateTrades.tradeRatios},
            {key: 'patternId', message: RequiredInputSettings.patternId},
        ].filter((validation) =>
            !getNested(backtestSettingInputData, validation.key) ||
            (Array.isArray(getNested(backtestSettingInputData, validation.key)) && getNested(backtestSettingInputData, validation.key).length === 0))
    }

    function validateInput() {
        setFormErrors({})

        const invalidInputs = getInvalidInputs();

        invalidInputs
            .forEach((validation) => {
            setFormErrors((prevState) => {
                const newState = { ...prevState };
                setNestedValue(newState, validation.key, validation.message)
                return newState;
            });
        })

        return !invalidInputs.length
    }

    const handleDateInputChange = (name: string, value: number) => {
        let newDate = new Date(value)

        // Validates that the date is not before the min date or after the current date
        if (newDate < minDateForSymbol) {
            newDate = minDateForSymbol;
        } else if (newDate > new Date()) {
            newDate = new Date();
        }

        // Sets the time to 00:00:00:000 for the start date and 23:59:59:999 for the end date
        if (name === "dateRange.startDate") {
            newDate.setHours(0, 0, 0, 0);
        } else if (name === "dateRange.endDate") {
            newDate.setHours(23, 59, 59, 999);
        }

        // Validates that the start date is not after the end date and vice versa
        if (name === "dateRange.startDate" && newDate.getTime() >= backtestSettingInputData.dateRange.endDate) {
            const newEndDate = new Date(newDate);
            newEndDate.setHours(23, 59, 59, 999);

            handleInputChange("dateRange.endDate", newEndDate.getTime());
            setSnackbarSettings({severity: 'warning', message: "End date adjusted as start date cannot be after end date.", autoHideDuration: 6000})
        } else if (name === "dateRange.endDate" && newDate.getTime() <= backtestSettingInputData.dateRange.startDate) {
            const newStartDate = new Date(newDate);
            newStartDate.setHours(0, 0, 0, 0);
            handleInputChange("dateRange.startDate", newStartDate.getTime());
            setSnackbarSettings({severity: 'warning', message: "Start date adjusted as end date cannot be before start date.", autoHideDuration: 6000})
        }

        handleInputChange(name, newDate.getTime());
    };

    const handleTimeInputChange = (name: string, value: number) => {
        if (name === "startTime" && value > backtestSettingInputData.endTime) {
            handleInputChange('endTime', value);
            setSnackbarSettings({severity: 'warning', message: "Start time cannot be after end time.", autoHideDuration: 6000})
            setPickerKey(Date.now());  // Force a remount
        } else if (name === "endTime" && value < backtestSettingInputData.startTime) {
            value = backtestSettingInputData.startTime;
            setSnackbarSettings({severity: 'warning', message: "End time cannot be before start time.", autoHideDuration: 6000})
            setPickerKey(Date.now());  // Force a remount
        }

        if (name === "startTime") {
            setLastStartTimeInput(value)
        } else if (name === "endTime") {
            setLastEndTimeInput(value)
        }
        handleInputChange(name, value);

    };

    const selectSymbol = (symbolChosen: ISymbol) => {
        handleInputChange('executionSymbol', symbolChosen.name);
        const symbolObject = symbols?.find((symbol: any) => symbol.name === symbolChosen.name);
        if (symbolObject) {
            handleInputChange('analyzeTrades.contractSize', getContractSize(symbolObject));
        }
    }

    const patternStrategySubTitle = <>
        <Box display="flex" gap={1} alignItems={"center"}>
            <Box display={"flex"} alignItems={'center'} gap={1}><b>Pattern:</b> {pattern
                ? <Typography variant={"body1"}>{pattern.name} <Typography variant={"caption"}>(v{pattern.version})</Typography></Typography>
                : <b style={{color: 'rgb(201, 80, 80)'}}>None selected</b>}</Box>
            <Box><b>Symbol:</b> {backtestSettingInputData.executionSymbol}</Box>
            <Box><b>Date range:</b> {new Date(backtestSettingInputData.dateRange.startDate).toLocaleDateString()} - {new Date(backtestSettingInputData.dateRange.endDate).toLocaleDateString()}</Box>
            <Box><b>Trigger:</b> {pattern && `${pattern.trigger.chartDetails.periodicity} ${pattern.trigger.chartDetails.timeframe}`} at post</Box>
        </Box>
    </>

    const accountDetailsSubTitle = <>
        <Box display="flex" gap={1} alignItems={"center"}>
            <Box><b>Equity:</b> ${backtestSettingInputData.analyzeTrades.equity}</Box>
            <Box><b>Risk per trade:</b> {(backtestSettingInputData.analyzeTrades.riskPerTrade * 100).toFixed(2)}%</Box>
            <Box><b>Commission:</b> ${backtestSettingInputData.analyzeTrades.commissions}</Box>
            <Box><b>Max open trades:</b> {backtestSettingInputData.analyzeTrades.tradeLimit ? backtestSettingInputData.analyzeTrades.tradeLimit : "∞"}</Box>
            <Box><b>Max Risk:</b> {backtestSettingInputData.analyzeTrades.maxRisk ? (backtestSettingInputData.analyzeTrades.maxRisk * 100).toFixed(2) : '∞'}</Box>
            <Box><b>Daily trade limit:</b> {backtestSettingInputData.analyzeTrades.dailyTradeLimit ? backtestSettingInputData.analyzeTrades.dailyTradeLimit : "∞"}</Box>
            <Box><b>Contract Size:</b> {backtestSettingInputData.analyzeTrades.contractSize ? backtestSettingInputData.analyzeTrades.contractSize : "1"}</Box>
        </Box>
    </>

    const tradeExitSubTitle = <>
        <Box display="flex" gap={1} alignItems={"center"}>
            <Box><b>Exit Strategy:</b> {backtestSettingInputData.generateTrades.exitStrategy === "peak_valley" ? "Fractal" : backtestSettingInputData.generateTrades.exitStrategy[0].toUpperCase() + backtestSettingInputData.generateTrades.exitStrategy.slice(1)}</Box>
            <Box><b>Trade Ratios:</b> {backtestSettingInputData.generateTrades.tradeRatios.length ? backtestSettingInputData.generateTrades.tradeRatios.map((ratio: any) => `${ratio.risk}/${ratio.reward}`).join(', ') : <b style={{color: 'rgb(201, 80, 80)'}}>None selected</b>}</Box>
        </Box>
    </>

    const tradeExitTitle = <>
        <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="h5">Trade Exit</Typography>
            <InfoIcon
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        color: '#00A1FF',
                    },
                }}
                fontSize={'small'}
                onClick={(event) => {
                    event.stopPropagation();
                    setIsExitStrategyDrawerOpen(true)
                }}
            />
        </Box>
    </>

    return (
        <>
                <Box display={"flex"} flexDirection={"column"} width={'100%'}>
                    <BacktestHeaderBar />
                    <StyledContainer sx={{ py: 2, overflowY: "auto"}}>
                        <Box component="form">
                            <CollapsibleInputContainer title={<Typography variant={'h5'}>Pattern Strategy</Typography>} defaultOpen={true} subTitle={patternStrategySubTitle}>
                                <Grid container spacing={2} flexDirection={"column"}>
                                  <Grid container item spacing={2}>
                                    <Grid item lg={3} sm={6} xs={12}>
                                        <PatternSelect
                                            pattern={pattern}
                                            onSelect={(pattern) => {
                                                handleInputChange("gcid", usermeta.gcid);
                                                handleInputChange("patternId", pattern.id);
                                                handleInputChange("patternVersion", pattern.version);
                                            }}
                                            error={{
                                                hasError: !!formErrors.patternId,
                                                message: formErrors.patternId
                                            }}/>
                                    </Grid>
                                      <Grid item lg={3} sm={6} xs={12} style={{ position: 'relative' }}>
                                          <SymbolSelect
                                                symbol={backtestSettingInputData.executionSymbol}
                                                onSelect={(symbol: ISymbol) => selectSymbol(symbol)}
                                          />
                                      </Grid>
                                  </Grid>
                                  <Grid container item spacing={2}>
                                    <Grid item lg={3} sm={6} xs={12}>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            sx={{width: "100%"}}
                                            label="Backtest start date"
                                            value={dayjs(new Date(backtestSettingInputData.dateRange.startDate))}
                                            minDate={dayjs(new Date(minDateForSymbol))}
                                            maxDate={dayjs()}
                                            onChange={(value: any) => handleDateInputChange("dateRange.startDate", value.$d.getTime())}
                                        />

                                      </LocalizationProvider>
                                    </Grid>

                                    <Grid item lg={3} sm={6} xs={12}>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            sx={{width: "100%"}}
                                            label="Backtest end date"
                                            value={dayjs(new Date(backtestSettingInputData.dateRange.endDate))}
                                            minDate={dayjs(new Date(minDateForSymbol))}
                                            maxDate={dayjs()}
                                            onChange={(value: any) => handleDateInputChange("dateRange.endDate", value.$d.getTime())}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                      <Grid item lg={2} sm={4} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                          <GreenChartTooltip title={"and all night."}>
                                              <Typography variant={"body1"}>Trade All Day</Typography>
                                          </GreenChartTooltip>
                                          <Switch
                                              checked={isAllDaySelected}
                                              onChange={(e) => {
                                                  if (e.target.checked) {
                                                      const time = new Date();
                                                      time.setHours(0, 0, 0, 0);
                                                      handleInputChange('startTime', time.getTime());
                                                      handleInputChange('endTime', time.getTime());
                                                  } else {
                                                      handleInputChange('startTime', lastStartTimeInput);
                                                      handleInputChange('endTime', lastEndTimeInput);
                                                  }
                                                  setIsAllDaySelected(e.target.checked)
                                              }}
                                              color="primary"
                                          />
                                      </Grid>
                                      {
                                            !isAllDaySelected && <>
                                              <Grid item lg={2} sm={4} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                  <TimePicker
                                                    sx={{width: "100%"}}
                                                    key={pickerKey}
                                                    label="Daily trading start time"
                                                    value={dayjs(backtestSettingInputData.startTime)}
                                                    onSelectedSectionsChange={(value: any) => value ?? handleTimeInputChange('startTime', startTempTimeRef.current)}
                                                    onChange={(value: any) => {
                                                        const newValue = value.valueOf();
                                                        setStartTempTime(newValue);
                                                        startTempTimeRef.current = newValue;
                                                    }}
                                                    onClose={() => handleTimeInputChange('startTime', startTempTimeRef.current)}
                                                  />
                                                </LocalizationProvider>
                                              </Grid>
                                              <Grid item lg={2} sm={4} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                  <TimePicker
                                                    sx={{width: "100%"}}
                                                    key={pickerKey}
                                                    label="Daily trading end time"
                                                    value={dayjs(backtestSettingInputData.endTime)}
                                                    onSelectedSectionsChange={(value: any) => value ?? handleTimeInputChange('endTime', endTempTimeRef.current)}
                                                    onChange={(value: any) => {
                                                        const newValue = value.valueOf();
                                                        setEndTempTime(newValue);
                                                        endTempTimeRef.current = newValue;
                                                    }}
                                                    onClose={() => handleTimeInputChange('endTime', endTempTimeRef.current)}
                                                  />
                                                </LocalizationProvider>
                                              </Grid>
                                          </>
                                      }

                                  </Grid>
                                </Grid>
                            </CollapsibleInputContainer>


                            <CollapsibleInputContainer title={<Typography variant={'h5'}>Account Details</Typography>} subTitle={accountDetailsSubTitle} >
                                <Grid container spacing={2} flexDirection={"column"}>
                                  <Grid container item spacing={2}>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Equity</InputLabel>
                                        <OutlinedInput
                                          value={backtestSettingInputData.analyzeTrades.equity}
                                          name="analyzeTrades.equity"
                                          onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                                          type={"number"}
                                          inputProps={{min: 0}}
                                          id="outlined-adornment-amount"
                                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                          label="Equity"
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Risk per trade</InputLabel>
                                        <OutlinedInput
                                          value={(backtestSettingInputData.analyzeTrades.riskPerTrade * 100).toFixed(2)}
                                          name="analyzeTrades.riskPerTrade"
                                          onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value) / 100)}
                                          type={"number"}
                                          inputProps={{step: .5, min: .5}}
                                          id="outlined-adornment-amount"
                                          startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                          label="Risk per trade"
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Commissions</InputLabel>
                                        <OutlinedInput
                                          value={backtestSettingInputData.analyzeTrades.commissions}
                                          name="analyzeTrades.commissions"
                                          onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                                          type={"number"}
                                          inputProps={{min: 0}}
                                          id="outlined-adornment-amount"
                                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                          label="Commissions"
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Max open positions</InputLabel>
                                        <OutlinedInput
                                          value={
                                              backtestSettingInputData.analyzeTrades.tradeLimit === 0
                                                  ? ""
                                                  : backtestSettingInputData.analyzeTrades.tradeLimit
                                          }
                                          name="analyzeTrades.tradeLimit"
                                          onChange={(e) => {
                                              if (parseInt(e.target.value) === 1) {
                                                  handleInputChange("analyzeTrades.allowHedging", false)
                                              }
                                              handleInputChange(e.target.name, parseInt(e.target.value))
                                          }}
                                          type={"number"}
                                          inputProps={{min: 0}}
                                          id="outlined-adornment-amount"
                                          startAdornment={<InputAdornment position="start">#</InputAdornment>}
                                          label="Max open positions"
                                          placeholder="∞"
                                          endAdornment={
                                              <InputAdornment position="end">
                                                  <GreenChartTooltip
                                                      title={
                                                          <Box className="tooltip-text">
                                                              {backtestSettingInputData.analyzeTrades.tradeLimit === 1
                                                                  ? "Change max open positions to enable hedging"
                                                                  : backtestSettingInputData.analyzeTrades.allowHedging
                                                                      ? "Disable hedging"
                                                                      : "Allow hedging"}
                                                          </Box>
                                                      }
                                                      arrow
                                                  >
                                                        <span>
                                                          <Switch
                                                              disabled={backtestSettingInputData.analyzeTrades.tradeLimit === 1}
                                                              checked={!!backtestSettingInputData.analyzeTrades.allowHedging}
                                                              onChange={(e) =>
                                                                  handleInputChange("analyzeTrades.allowHedging", e.target.checked)
                                                              }
                                                              color="primary"
                                                          />
                                                        </span>
                                                  </GreenChartTooltip>
                                              </InputAdornment>
                                          }
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Max risk</InputLabel>
                                        <OutlinedInput
                                          value={
                                              backtestSettingInputData.analyzeTrades.maxRisk
                                                  ? backtestSettingInputData.analyzeTrades.maxRisk * 100
                                                  : ""
                                          }
                                          placeholder="∞"
                                          name="analyzeTrades.maxRisk"
                                          onChange={(e) =>
                                              handleInputChange(e.target.name, parseFloat(e.target.value) / 100)
                                          }
                                          type={"number"}
                                          id="outlined-adornment-amount"
                                          startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                          label="Max risk"
                                          inputProps={{min: 0}}
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                      <FormControl fullWidth>
                                          <InputLabel htmlFor="outlined-adornment-amount">Daily trade limit</InputLabel>
                                          <OutlinedInput
                                              value={
                                                  backtestSettingInputData.analyzeTrades.dailyTradeLimit === 0
                                                      ? ""
                                                      : backtestSettingInputData.analyzeTrades.dailyTradeLimit
                                              }
                                              placeholder="∞"
                                              name="analyzeTrades.dailyTradeLimit"
                                              onChange={(e) =>
                                                  handleInputChange(e.target.name, parseInt(e.target.value))
                                              }
                                              type={"number"}
                                              id="outlined-adornment-amount"
                                              startAdornment={<InputAdornment position="start">#</InputAdornment>}
                                              label="Daily trade limit"
                                              inputProps={{min: 0}}
                                          />
                                      </FormControl>
                                    </Grid>

                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                      <FormControl fullWidth>
                                          <InputLabel htmlFor="outlined-adornment-amount">Contract Size</InputLabel>
                                          <OutlinedInput
                                              value={backtestSettingInputData.analyzeTrades.contractSize}
                                              placeholder="∞"
                                              name="analyzeTrades.contractSize"
                                              onChange={(e) => {
                                                  const contractSizeMap = JSON.parse(localStorage.getItem('contractSize') || '{}') || {};
                                                  contractSizeMap[backtestSettingInputData.executionSymbol] = e.target.value;
                                                  localStorage.setItem('contractSize', JSON.stringify(contractSizeMap));
                                                  handleInputChange(e.target.name, parseInt(e.target.value))
                                              }}
                                              type={"number"}
                                              id="outlined-adornment-amount"
                                              startAdornment={<InputAdornment position="start">#</InputAdornment>}
                                              label="Contract Size"
                                              inputProps={{min: 1}}
                                          />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                            </CollapsibleInputContainer>

                            <CollapsibleInputContainer title={tradeExitTitle} subTitle={tradeExitSubTitle}>
                                <Grid container spacing={2} flexDirection={"column"}>
                                  <Grid container item spacing={2}>
                                    <Grid item lg={3} xs={12}>
                                      <FormControl variant="outlined" fullWidth>
                                          <InputLabel id="timeframe-label">Exit Strategy</InputLabel>
                                          <Select
                                              labelId="timeframe-label"
                                              value={backtestSettingInputData.generateTrades.exitStrategy}
                                              name="generateTrades.exitStrategy"
                                              onChange={(e) => {
                                                  if ((backtestSettingInputData.generateTrades.exitStrategy !== "fixed" && e.target.value === "fixed")
                                                      || (backtestSettingInputData.generateTrades.exitStrategy === "fixed" && e.target.value !== "fixed")) {
                                                      handleInputChange("generateTrades.tradeRatios", [])
                                                  }
                                                  handleInputChange(e.target.name, e.target.value)
                                              }}
                                              label="Exit Strategy"
                                            >
                                                  <MenuItem value="atr">ATR</MenuItem>
                                                  <MenuItem value="incremental">Incremental</MenuItem>
                                                  <MenuItem value="fixed">Fixed</MenuItem>
                                                  <MenuItem value="peak_valley">Fractal</MenuItem>
                                            </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  <Grid container item spacing={2}>
                                    <ExitStrategyInputs backtestSettingInputData={backtestSettingInputData} handleInputChange={handleInputChange} formErrors={formErrors}/>
                                  </Grid>
                                </Grid>

                                <Grid item>
                                  <Grid container spacing={2} flexDirection={"column"}>
                                    <Grid container item spacing={2}>
                                      <Grid item lg={3} xs={12} display={"flex"} alignItems={"center"}>
                                        <Typography variant={"body1"}>Force exit on time elapsed</Typography>
                                        <FormControl>
                                          <Switch
                                            checked={!!backtestSettingInputData.analyzeTrades.forceExit?.time}
                                            onChange={(e) => {
                                                const { time, ...otherForceExitProps } = backtestSettingInputData.analyzeTrades.forceExit!;
                                                handleInputChange("analyzeTrades.forceExit", {
                                                    ...otherForceExitProps,
                                                    ...(e.target.checked
                                                        ? {
                                                            time: {
                                                                periodicity: 1,
                                                                timeframe: 'minute',
                                                            }
                                                        }
                                                        : {}),
                                                });
                                            }}
                                            color="primary"
                                          />


                                        </FormControl>
                                      </Grid>

                                      <Grid container item xs={12} lg={9} spacing={2}>

                                          {
                                              backtestSettingInputData.analyzeTrades.forceExit?.time
                                                  ? <>
                                                      <Grid item lg={3} xs={12}>
                                                          <FormControl fullWidth>
                                                              <InputLabel>Force exit periodicity</InputLabel>
                                                              <OutlinedInput
                                                                  value={backtestSettingInputData.analyzeTrades.forceExit.time.periodicity}
                                                                  name="analyzeTrades.forceExit.time.periodicity"
                                                                  onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                                                                  type={"number"}
                                                                  inputProps={{min: 0}}
                                                                  label="Force exit periodicity"
                                                              />
                                                          </FormControl>
                                                      </Grid>
                                                      <Grid item lg={3} xs={12}>
                                                          <FormControl fullWidth>
                                                              <InputLabel id="timeframe-label">Force exit timeframe</InputLabel>
                                                              <Select
                                                                  labelId="timeframe-label"
                                                                  value={backtestSettingInputData.analyzeTrades.forceExit.time.timeframe}
                                                                  name="analyzeTrades.forceExit.time.timeframe"
                                                                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                                  label="Force exit timeframe"
                                                              >
                                                                  <MenuItem value="minute">Minute</MenuItem>
                                                                  <MenuItem value="hour">Hour</MenuItem>
                                                              </Select>
                                                          </FormControl>
                                                      </Grid>
                                                  </>
                                                  : null
                                          }
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                            </CollapsibleInputContainer>


                        </Box>

                    </StyledContainer>

                    <Box flex="1" overflow="auto"/>
                    {!isMobile && <BacktestActionBar/>}
                </Box>
                <ExitStrategyDrawer
                    isOpen={isExitStrategyDrawerOpen}
                    onClose={() => setIsExitStrategyDrawerOpen(false)}
                />
        </>

    )
}
