import {Paper, Popper, Typography} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import BacktestGradeDrawer from "./BacktestGradeDrawer";

function SummaryGradeIcon({ summary }: any) {

    const theme = useTheme();

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [arrowRef, setArrowRef] = React.useState(null);

    const profitGrade = gradeProfit(summary.profitPercentage || 0);
    const absoluteDrawdownGrade = gradeAbsoluteDrawdown(summary.absoluteDrawdownPercentage || 0);
    const relativeDrawdownGrade = gradeRelativeDrawdown(summary.relativeDrawdownPercentage || 0);

    const summaryGrade = gradeBacktest({
        profitGrade,
        absoluteDrawdownGrade,
        relativeDrawdownGrade,
        dateRangeGrade: 100,
        tradeRatioGrade: 100,
    });

    function gradeBacktest(grades: any): number {

        const gradesWithWeights = [
            {grade: grades.profitGrade || 0, weight: 0.4},
            {grade: grades.absoluteDrawdownGrade || 0, weight: 0.225},
            {grade: grades.relativeDrawdownGrade || 0, weight: 0.225},
            {grade: grades.dateRangeGrade || 0, weight: 0.1},
            {grade: grades.tradeRatioGrade || 0, weight: 0.05},
        ]

        const totalWeight = gradesWithWeights.reduce((acc, item) => acc + item.weight, 0);
        const normalizedGrades = gradesWithWeights.map(item => ({
            ...item,
            weight: item.weight / totalWeight
        }));


        const finalGrade = normalizedGrades.reduce((acc, item) => acc + (item.grade * item.weight), 0);

        return Math.round(finalGrade);
    }

    function gradeProfit(profitPercentage: number): number {
        if (profitPercentage > 30) return 100;
        if (profitPercentage > 10) return 80;
        if (profitPercentage > 5) return 70;
        if (profitPercentage > 0) return 60;
        return 20;
    }

    function gradeAbsoluteDrawdown(drawdownPercentage: number): number {
        if (drawdownPercentage < 10) return 100;
        if (drawdownPercentage < 20) return 80;
        if (drawdownPercentage < 40) return 70;
        if (drawdownPercentage < 60) return 60;
        return 20;
    }

    function gradeRelativeDrawdown(drawdownPercentage: number): number {
        if (drawdownPercentage < 10) return 100;
        if (drawdownPercentage < 15) return 80;
        if (drawdownPercentage < 40) return 70;
        if (drawdownPercentage < 60) return 60;
        return 20;
    }

    function getLetterGrade(grade: number): string {
        if (grade >= 90) return "A";
        if (grade >= 80) return "B";
        if (grade >= 70) return "C";
        if (grade >= 60) return "D";
        return "F";
    }

    function getGradeColor(grade: number): string {
        if (grade >= 90) return "green";
        if (grade >= 80) return "lightgreen";
        if (grade >= 70) return "yellow";
        if (grade >= 60) return "orange";
        return "red";
    }

    return (
        <>
            <Typography variant="body2"
                        sx={{
                            lineHeight: 1,
                            textShadow: '0 0 20px currentColor',
                            cursor: 'pointer',
                        }}
                        color={getGradeColor(summaryGrade)}
                        ref={anchorRef}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}>
                {getLetterGrade(summaryGrade)}
            </Typography>
            <Popper
                modifiers={
                    [
                        {
                            name: 'arrow',
                            enabled: true,
                            options: {
                                element: arrowRef,
                                offset: 10,
                            },
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [-4, 4],
                            },
                        },
                    ]}
                open={open}
                anchorEl={anchorRef.current}
                placement={'bottom-start'}
                disablePortal={false}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                sx={{zIndex: 9999}}
            >
                <Box sx={{
                    position: 'absolute',
                    fontSize: 7,
                    left: 0,
                    marginLeft: '-0.45em',
                    marginTop: '-1.9em',
                    height: '3em',
                    width: '1em',
                    '&::before': {
                        content: '""',
                        margin: 'auto',
                        display: 'block',
                        width: 0,
                        height: 0,
                        borderStyle: 'solid',
                        borderWidth: '1em 1em 1em 1em',
                        borderColor: `transparent transparent ${theme.palette.background.custom3} transparent`,
                    },
                }}
                     ref={setArrowRef}
                     className="arrow"
                />
                <Paper sx={{
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.custom3,
                    '--Paper-overlay': 'none !important',
                }}>
                    <Box display={'grid'} gridTemplateRows={'1fr'} gridTemplateColumns={'7fr 1fr'} alignItems={'center'} marginBottom={1} borderBottom={1}>
                        <Typography variant="body2" color={'textPrimary'}>Trade Ratio Grades</Typography>
                        <InfoIcon fontSize={'small'}
                                  sx={{
                                      m: 'auto',
                                      '&:hover': {
                                          cursor: 'pointer',
                                          color: '#00A1FF',
                                      },
                                  }}
                                  onClick={(e) => {
                                      e.stopPropagation()
                                      setOpenDrawer(true)
                                  }}
                        />
                    </Box>
                    <Box display={'grid'} gridTemplateRows={'1fr 1fr 1fr'} gridTemplateColumns={'7fr 1fr'} alignItems={'center'}>
                        <Typography variant="body2" color={'textSecondary'}>Profitability</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(profitGrade)}>{getLetterGrade(profitGrade)}</Typography>
                        <Typography variant="body2" color={'textSecondary'}>Abs. Drawdown</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(absoluteDrawdownGrade)}>{getLetterGrade(absoluteDrawdownGrade)}</Typography>
                        <Typography variant="body2" color={'textSecondary'}>Rel. Drawdown</Typography>
                        <Typography variant={'body1'} mx={'auto'} color={getGradeColor(relativeDrawdownGrade)}>{getLetterGrade(relativeDrawdownGrade)}</Typography>
                    </Box>
                </Paper>

            </Popper>
            {openDrawer && <BacktestGradeDrawer isOpen={openDrawer} onClose={() => setOpenDrawer(false)}/>}
        </>
    );
}

export default SummaryGradeIcon;
