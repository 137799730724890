import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    LinearProgress
} from "@mui/material";
import {useModalContext} from "../../contexts/ModalProvider";
import {Alert, useApplicationSuiteContext} from "../../contexts/ApplicationSuiteProvider";
import setNestedValue from "lodash.set";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AlertNotificationForm from "./alertForms/AlertNotificationForm";
import AlertNotificationTimeOfDayForm from "./alertForms/AlertNotificationTimeOfDayForm";
import CloseIcon from '@mui/icons-material/Close';
import AlertPatternSelectForm from "./alertForms/AlertPatternSelectForm";
import {NotificationAdd} from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import IconButton from "@mui/material/IconButton";
import {collection, doc, setDoc} from "firebase/firestore";
import {firebase_firestore} from "../../common/firebaseConfig";
import AlertPattenDetailsForm from "./alertForms/AlertPattenDetailsForm";
import AlertLastChancePhoneNumberInputForm from "./alertForms/AlertLastChancePhoneNumberInputForm";

function CreateAlertModal({alert: initialAlert}: { alert: Alert }) {

    const {
        tradingHouseObject
    } = useApplicationSuiteContext();

    const [alert, setAlert] = useState<Alert>(initialAlert);
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = React.useRef<Slider | null>(null);
    const dialogContentRef = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setAlert(initialAlert);
    }, [initialAlert]);

    useEffect(() => {
        if (dialogContentRef.current) {
            dialogContentRef.current.scrollTop = 0;
        }
    }, [currentSlide]);

    const {
        isCreateAlertModalOpen,
        setIsCreateAlertModalOpen,
    } = useModalContext();

    const steps: any = useMemo(() => getSteps(initialAlert, alert), [initialAlert, alert]);

    function navigateToCharts() {
        if (tradingHouseObject.routes.greenchart) {
            window.open(tradingHouseObject.routes.greenchart, "_blank");
        }
        onClose()
    }

    function onClose() {
        setAlert(initialAlert);
        setCurrentSlide(0);
        setIsCreateAlertModalOpen(false);
    }

    const handleNextStep = useCallback(() => {
        setCurrentSlide((prev) => prev + 1);
        sliderRef.current?.slickNext();
    }, [])

    const handlePreviousStep = useCallback(() => {
        setCurrentSlide((prev) => prev - 1);
        sliderRef.current?.slickPrev();
    }, [])

    const handleAlertCreate = useCallback(async () => {
        const docRef = doc(collection(firebase_firestore, "tradingPlans"));
        alert.id = docRef.id;
        await setDoc(docRef, alert);
        handleNextStep()
    }, [alert, handleNextStep])

    const handleAlertKeyChange = useCallback((key: string, value: any) => {
        setAlert((prev) => setNestedValue({...prev}, key, value));
    }, [setAlert])

    const slickSettings = {
        dots: false,
        arrows: false,
        swipe: false,
        infinite: false,
        draggable: false,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return <Dialog
        open={isCreateAlertModalOpen}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
    >
        <DialogTitle>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h6">Set Alert</Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon fontSize={'small'}/>
                </IconButton>
            </Box>
        </DialogTitle>

        <Box width={'100%'}>
            {currentSlide >= 1 && <LinearProgress variant="determinate" value={(currentSlide-1)/(steps.length-2)*100} color={'success'} />}
        </Box>

        <DialogContent
            ref={dialogContentRef}
            dividers
            sx={{overflowY: 'auto', overflowX: 'hidden'}}>

            <Slider {...slickSettings} ref={sliderRef}>
                {steps.map((step: any, index: any) => (
                    <Box key={index}>
                        {steps[currentSlide].slideName && <Box display={'flex'} flexDirection={'column'} mb={2} gap={1}>
                          <Typography fontWeight={'bold'}>{steps[currentSlide].slideName}</Typography>
                          <Typography variant="body2" color="textSecondary">{steps[currentSlide].subtitle}</Typography>
                        </Box>}

                        {React.createElement(step.slideComponent, {alert: alert, onAlertKeyChange: handleAlertKeyChange})}
                    </Box>
                ))}
            </Slider>
        </DialogContent>

        <DialogActions sx={{height: 40}}>
            {/*{steps[currentSlide].actionsComponent}*/}
            {React.createElement(steps[currentSlide].actionsComponent, {alert, onNextStep: handleNextStep, onPreviousStep: handlePreviousStep, onAlertCreate: handleAlertCreate, navigateToCharts: navigateToCharts})}
        </DialogActions>
    </Dialog>
}

function SetupAlertSlide() {
    return <Box display={"flex"} flexDirection={"column"} gap={2} px={2} alignItems={'center'} textAlign={'center'}>
        <NotificationAdd sx={{fontSize: '64px'}} color={'success'}/>
        <Typography variant={"h4"} fontWeight={'bold'}>Setup Trade Alert</Typography>
        <Typography variant={'body2'} color={'textSecondary'}>Create alerts based on your strategies. Get instant notifications, keeping you informed about potential trade opportunities. This ensures you never miss a moment to execute a favorable trade.</Typography>
    </Box>
}

function SetupAlertAgreementSlide() {
    return <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Typography variant={'body1'}>Pattern Alerts Agreement</Typography>
        <Typography variant={'body2'} color={'textSecondary'}>By using Pattern Alerts, you acknowledge that you understand and agree to the following:</Typography>
        <Typography variant={'body2'} color={'textSecondary'}>
            Use Pattern Alerts at your own risk. When used with proper training they can be a great tool to help you identify key setups and movements in the market. Rules and conditions that govern a Pattern Alert are the sole responsibility of the Pattern Author. You should test and prove a Pattern Alert before employing them with your own trading strategy. Pattern Alerts are not trading advice, and you should not rely solely on them for your trade decisions. Seek for professional advice on how to properly account for risk and execute trades with your broker.  By providing your email and/or mobile phone number in the Pattern Alerts tool you agree to have GreenChart send you alert notifications. GreenChart is not responsible for any charges that your mobile phone carrier may assess for text messaging. You can remove text or email notifications at any time in the Alert edit view. GreenChart cannot guarantee that notifications will be delivered to your email or mobile phone due to possible email or mobile phone provider restrictions. Contact your provider to remove any restrictions.
        </Typography>
    </Box>
}

function AlertCreateConfirmationForm() {
    return (
        <>
            <Box display={"flex"} flexDirection={"column"} gap={2} px={2} alignItems={'center'} textAlign={'center'}>
                <CheckIcon sx={{fontSize: '64px'}} color={'success'}/>
                <Typography variant={"h4"} fontWeight={'bold'}>Setup Complete</Typography>
                <Typography variant={"body2"} color={'textSecondary'}>Your custom alert has been created! To monitor your new alert go to the charts view.</Typography>
            </Box>
        </>
    )
}

function getSteps(startingAlert: Alert, alert: Alert) {

    const slides: any = [
        {
            slideComponent: SetupAlertSlide,
            actionsComponent: ({onNextStep}: any) => <>
                <Button onClick={onNextStep} variant={"contained"} color={'success'}>Begin</Button>
            </>
        },
        {
            slideComponent: SetupAlertAgreementSlide,
            actionsComponent: ({onNextStep}: any) => <>
                <Button onClick={onNextStep} variant={"contained"} color={'success'}>I Agree</Button>
            </>
        },
    ]

    if (startingAlert.pattern) {
        slides.push(
            {
                slideComponent: AlertPattenDetailsForm,
                slideName: 'Pattern Details',
                subtitle: 'Adjust any pattern details before creating the alert.',
                actionsComponent: ({onNextStep, onPreviousStep}: any) => <>
                    <Box display={'flex'} width={'100%'} height={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button onClick={onPreviousStep} color={'success'}>Back</Button>
                        <Button onClick={onNextStep} variant={"contained"} disabled={!alert.pattern} color={'success'}>
                            Continue
                        </Button>
                    </Box>
                </>
            },
            {
                slideComponent: AlertNotificationTimeOfDayForm,
                slideName: 'Alert Details',
                subtitle: 'Never miss a trade alert by adding when and how you want to be notified.',
                actionsComponent: ({onPreviousStep, onNextStep, onAlertCreate}: any) => <>
                    <Box display={'flex'} width={'100%'} height={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button onClick={onPreviousStep} color={'success'}>Back</Button>
                        <Button onClick={alert.user?.phoneNumber ? onAlertCreate : onNextStep} variant={"contained"} color={'success'}>
                            Finish Setup
                        </Button>
                    </Box>
                </>
            }
        )
    } else {
        slides.push(
            {
                slideComponent: AlertPatternSelectForm,
                slideName: 'Select a Strategy',
                subtitle: 'Choose a winning backtested strategy. The four listed here are just a sample of hundreds of available in our Showcase.',
                actionsComponent: ({alert, onNextStep, onPreviousStep}: any) => <>
                    <Box display={'flex'} width={'100%'} height={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button onClick={onPreviousStep} color={'success'}>Back</Button>
                        <Button onClick={onNextStep} variant={"contained"} disabled={!alert?.pattern} color={'success'}>
                            Continue
                        </Button>
                    </Box>
                </>
            },
            {
                slideComponent: AlertNotificationForm,
                slideName: 'Alert Details',
                subtitle: 'Never miss a trade alert by adding when and how you want to be notified.',
                actionsComponent: ({onPreviousStep, onNextStep, onAlertCreate}: any) => <>
                    <Box display={'flex'} width={'100%'} height={'100%'} justifyContent={'flex-end'}
                         alignItems={'center'}>
                        <Button onClick={onPreviousStep} color={'success'}>Back</Button>
                        <Button onClick={alert.user?.phoneNumber ? onAlertCreate : onNextStep} variant={"contained"} color={'success'}>
                            Finish Setup
                        </Button>
                    </Box>
                </>
            }
        )
    }

    if (!alert.user?.phoneNumber) {
        slides.push({
            slideComponent: AlertLastChancePhoneNumberInputForm,
            slideName: 'Add Text Alerts',
            subtitle: 'Get notified of trade alerts on the go by adding your phone number for text alerts.',
            actionsComponent: ({onPreviousStep, onAlertCreate}: any) => <>
                <Button onClick={onPreviousStep} color={'success'}>Back</Button>
                <Button onClick={onAlertCreate} variant={"contained"} color={'success'}>
                    Skip This Step
                </Button>
            </>
        })
    }

    slides.push({
        slideComponent: AlertCreateConfirmationForm,
        actionsComponent: ({navigateToCharts}: any) => <>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'} height={'100%'}>
                <Button onClick={navigateToCharts} variant={"contained"} sx={{mx: 'auto'}} color={'success'}>Monitor Alert</Button>
            </Box>
        </>
    })

    return slides

}

export default CreateAlertModal;
