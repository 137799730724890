import React, {createContext, useContext, ReactNode, useState, useCallback} from 'react';
import ConfirmDialog from "../components/backtestList/ConfirmDialog";
import {firebase_functions} from "../../../common/firebaseConfig";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {SnackbarHelperContext} from "../../../contexts/SnackbarHelperContext";
import {
    backtestFilterDefaultValues,
    BacktestFilterType,
    BacktestSortType, useApplicationSuiteContext
} from "../../../contexts/ApplicationSuiteProvider";
import usePaginatedLoader from "../../../hooks/usePaginatedLoader";

const backtestSortDefaultValue: BacktestSortType = {
    field: 'startTime',
    direction: 'desc',
};

interface BacktestReportContextProps {
    backtestToView: string | undefined;
    setBacktestToView: React.Dispatch<React.SetStateAction<string | undefined>>;
    dialogOpen: {type: string, data: { id: string, backup?: boolean }} | undefined;
    setOpenDialog: React.Dispatch<React.SetStateAction<{type: string, data: { id: string, backup?: boolean }} | undefined>>;
    backtestFilters: BacktestFilterType;
    setBacktestFilters: React.Dispatch<React.SetStateAction<BacktestFilterType>>;
    backtestSort: BacktestSortType;
    setBacktestSort: React.Dispatch<React.SetStateAction<BacktestSortType>>;
    type: 'user_favorites' | 'user';
    setType: React.Dispatch<React.SetStateAction<'user_favorites' | 'user'>>;
    backtests: any[];
    loadMore: () => void;
    isLoading: boolean;
    totalCount: number;
}

export const BacktestReportContext = createContext<BacktestReportContextProps | undefined>(undefined);

interface Props {
    children: ReactNode;
}

export const BacktestReportProvider: React.FC<Props> = ({ children }) => {
    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const [backtestToView, setBacktestToView] = useState<string>();
    const [dialogOpen, setOpenDialog] = useState<{type: string, data: { id: string, backup?: boolean }}>();
    const [backtestFilters, setBacktestFilters] = useState<BacktestFilterType>(backtestFilterDefaultValues);
    const [backtestSort, setBacktestSort] = useState<BacktestSortType>(backtestSortDefaultValue);
    const [type, setType] = useState<'user_favorites' | 'user'>('user');

    const [deleteBacktestCallable] = useHttpsCallable(firebase_functions, "deleteBacktest")


    const [getBacktests] = useHttpsCallable(firebase_functions, "getBacktests");

    const func = useCallback(
        (params: any) => getBacktests({ ...params, tradingHouse, backtestFilters: backtestFilters, sort: backtestSort, type }),
        [getBacktests, tradingHouse, backtestFilters, backtestSort, type]
    );

    const { data: backtests, loadMore, isLoading, totalCount } = usePaginatedLoader({
        queryFunction: func,
        take: 15,
    });


    return (
        <BacktestReportContext.Provider value={{
            dialogOpen,
            setOpenDialog,
            backtestToView,
            setBacktestToView,
            backtestFilters,
            setBacktestFilters,
            backtestSort,
            setBacktestSort,
            type,
            setType,
            backtests: backtests || [],
            loadMore,
            isLoading,
            totalCount,
        }}>
            {children}


            <ConfirmDialog
                open={!!dialogOpen && dialogOpen.type === 'delete'}
                title="Confirm Backtest Deletion"
                description="By proceeding, all trades linked to this backtest and the summary will be deleted."
                onClose={() => setOpenDialog(undefined)}
                onConfirm={() => {
                    setOpenDialog(undefined);
                    if (dialogOpen && dialogOpen.data && dialogOpen.data.id) {
                        deleteBacktestCallable({backtestId: dialogOpen.data.id, backup: dialogOpen.data.backup});
                        setSnackbarSettings({severity: 'success', message: "Deleted backtest.", autoHideDuration: 6000})
                    }
                }}
            />

        </BacktestReportContext.Provider>
    );
}

// Custom hook to use this contexts
export const useBacktestReportContext = (): BacktestReportContextProps => {
    const context = useContext(BacktestReportContext);
    if (!context) {
        throw new Error('useBacktestBacktestContext must be used within a BacktestReportProvider');
    }
    return context;
}
