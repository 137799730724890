import React, {useCallback, useEffect, useRef, useState} from "react";
import {AccordionDetails, AccordionSummary, Chip, Typography} from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import {firebase_functions} from "../../../common/firebaseConfig";
import {defaultInputSettings, useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import {styled, useTheme} from '@mui/material/styles';
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import {Tabs} from "../../../layout/MainLayout";
import ReportCardList from "../../../components/backtests/ReportCardList";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {collection, query, where, getCountFromServer} from "firebase/firestore";
import {firebase_firestore} from "../../../common/firebaseConfig";
import usePaginatedLoader from "../../../hooks/usePaginatedLoader";
import ViewBacktestSettingsActionChip from "../../../components/actionChips/ViewBacktestSettingsActionChip";
import ViewPatternActionChip from "../../../components/actionChips/ViewPatternActionChip";
import CreateAlertActionChip from "../../../components/actionChips/CreateAlertActionChip";
import SummarizeIcon from "@mui/icons-material/Summarize";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

function PatternBacktestView(props: any) {
    const theme = useTheme();

    const {
        pattern,
    } = props;

    const {
        usermeta,
        setActiveTab,
        setPattern,
    } = useApplicationSuiteContext();

    const containerRef = useRef(null);
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const currentRef = containerRef.current; // Capture the ref

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === currentRef) {
                    const width = entry.contentRect.width;
                    // Define your width threshold (e.g., 600px)
                    setIsNarrow(width < 800);
                }
            }
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    const [alertCount, setAlertCount] = React.useState(0);
    const [backtestCount, setBacktestCount] = React.useState(0);

    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    useEffect(() => {
        setTimeout(() => {
            getCountFromServer(
                query(
                    collection(firebase_firestore, `usermeta/${usermeta.gcid}/backtests`),
                    where("pattern.id", "==", pattern.id),
                )
            ).then((snapshot) => setBacktestCount(snapshot.data().count));

            getCountFromServer(
                query(
                    collection(firebase_firestore, `tradingPlans`),
                    where("pattern.id", "==", pattern.id),
                    where("user.gcid", "==", usermeta.gcid),
                )
            ).then((snapshot) => setAlertCount(snapshot.data().count));
        }, 500)


    }, [usermeta.gcid, pattern.id]);


    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    function goToPatternBuilder(event: any) {
        event.stopPropagation();

        setPattern(pattern);
        setActiveTab(Tabs.PatternBuilder)
    }

    function renderActions() {
        return <Box display={'flex'} gap={1} px={1}>
            <CreateAlertActionChip pattern={pattern} size={'small'} forceOpen={!isNarrow}/>
            <ViewBacktestSettingsActionChip backtest={{
                gcid: usermeta.gcid,
                backtestSettings: {
                    ...defaultInputSettings,
                    patternId: pattern.id,
                    patternVersion: pattern.version,
                    gcid: usermeta.gcid,
                }
            }} size={'small'} forceOpen={!isNarrow}/>
            <ViewPatternActionChip pattern={pattern} size={'small'} forceOpen={!isNarrow}/>
        </Box>
    }

    return (
        <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            sx={{
                backgroundColor: theme.palette.background.custom3,
                '.MuiAccordionSummary-root': {
                    justifyContent: 'flex-start',
                },
                '.MuiAccordionSummary-content': {
                    overflow: 'hidden'
                },
            }}
        >
            <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                expandIcon={<IconButton size={'small'}><ExpandMoreIcon fontSize={'small'}/></IconButton>}
                sx={{
                    '&:hover': {
                        backgroundColor: theme.palette.background.custom4,
                    },
                    '&:hover .MuiChip-root': {
                        backgroundColor: theme.palette.background.custom3
                    },
                }}
            >
                <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'} gap={1} ref={containerRef}>
                    <Typography color="text.primary"
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                                textOverflow={'ellipsis'}
                                onClick={goToPatternBuilder}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                        textDecoration: 'underline'
                                    }
                                }}
                    >
                        {pattern.name}
                    </Typography>

                    <Box display={'flex'} gap={1} justifyContent={'space-between'} flexGrow={1}>
                        <Box display={'flex'} gap={1} flexGrow={1}>
                            {!!backtestCount && <Chip icon={<SummarizeIcon/>} label={backtestCount} size={'small'} sx={{backgroundColor: theme.palette.background.custom4}}/>}
                            {!!alertCount && <Chip icon={<NotificationsIcon/>} label={alertCount} size={'small'} sx={{backgroundColor: theme.palette.background.custom4}}/>}
                        </Box>
                        {renderActions()}
                    </Box>
                </Box>
            </AccordionSummary>
            {
                expanded === "panel3" && <PatternDetails pattern={pattern}/>
            }
        </Accordion>
    );
}

function PatternDetails({pattern}: any) {
    const theme = useTheme();

    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const [getTraderBacktests] = useHttpsCallable(firebase_functions, "getBacktests");

    const func = useCallback(
        (params: any) => getTraderBacktests({ ...params, backtestFilters: {patternId: pattern.id}, sort: {field: 'startTime', direction: 'desc',}, tradingHouse, type: 'user' }),
        [getTraderBacktests, pattern.id, tradingHouse]
    );

    const { data: backtests, loadMore, isLoading, totalCount } = usePaginatedLoader({
        queryFunction: func,
        take: 20,
    });

    return (
        <AccordionDetails sx={{padding: 0}}>
            <Box sx={{maxHeight: '300px', backgroundColor: theme.palette.background.custom2}} display={'flex'} flexDirection={'column'} height={backtests?.length ? '300px' : 'auto'}>
                <ReportCardList
                    backtests={backtests}
                    loadMore={loadMore}
                    isLoading={isLoading}
                    totalCount={totalCount}
                    condensed
                    emptyListText={{
                        title: "No backtests for this pattern",
                        subTitle: "Run a backtest to see it here",
                        size: 'small'
                    }}
                />
            </Box>
        </AccordionDetails>
    )
}

export default PatternBacktestView;
