import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import ReportCardList from "../../../components/backtests/ReportCardList";
import useRecentlyViewedBacktests from "../../../hooks/useRecentlyViewedBacktests";
import SummarizeIcon from "@mui/icons-material/Summarize";

function RecentlyViewedBacktestsView() {
    const [backtests] = useRecentlyViewedBacktests();

    return <>
        <Box display={'flex'} alignItems={'center'} gap={1} color="white" mx={"auto"}>
            <SummarizeIcon fontSize={'medium'}/>
            <Typography variant={'h6'} fontWeight={'normal'}>
                Recently Viewed <Typography variant="body2" color="white" sx={{display: 'inline'}}>({backtests?.length || 0})</Typography>
            </Typography>
        </Box>
        <ReportCardList
            backtests={backtests}
            condensed
            emptyListText={{
                title: "No recently viewed backtests",
                subTitle: "Backtests you view will appear here",
                size: 'small'
            }}
        />
    </>
}

export default RecentlyViewedBacktestsView;
